@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';
@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .total-cost-clarity-content {
    display: flex;
    cursor: default;

    &__divider {
      border-left: 1px solid #000;
      position: relative;
      padding: 13px 0;
      display: inline-block;
      margin: 7px 10px 0 10px;
      height: 100%;

      &--percent-margin {
        margin: 8%;
      }

      span {
        background: #fff;
        padding: 0px 0;
        font-size: 9px;
        position: absolute;
        top: 7px;
        left: -7px;
      }
    }

    &__monthly-payment {
      .font-weight-medium;
      font-size: 25px;
      margin-top: 5px;
      font-weight: 500;

      strong {
        font-weight: 500;
      }

      span {
        font-size: 15px;
      }
    }

    &__upgrade-plus {
      strong {
        font-size: 15px;
        line-height: 18px;
      }

      margin-top: 0;
      font-size: inherit;
    }

    &__finance-message {
      font-size: 11px;
      width: 99%; //this fix is for IE browsers
      padding-bottom: 5px;
      strong {
        white-space: nowrap;
      }
    }

    &__upgrade-plus-finance-message {
      font-size: 11px;
      line-height: 14px;
      margin: 8px auto 4px;
    }

    &__learn-more-link {
      .font-weight-medium;

      font-size: 11px;
      padding: 0;
      height: 15px;
    }

    &__learn-more-upgrade-plus-link {
      line-height: 14px;
    }

    &__financeModalContainer .c-modal-grid.col-xs-8 {
      min-width: 643px;
      max-width: 643px;
      padding-top: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 30px !important;
      padding-left: 30px !important;
    }

    &__financeModalContainer .c-modal-grid,
    &__financeModalContainer .c-modal-small-view {
      background-color: #e0e6ef;
    }
  }
}
