// @import (reference) '~sc-pattern-library/less/variables.less';
@import (reference) "~@bestbuy/brix-web/less/";

.restricted-pricing {
  &__info-container {
    min-height: 100px;
    overflow: hidden;
  }

  &__info-title {
    .font-weight-medium;

    display: block;
    font-size: 20px;
    line-height: 24px;
    color: @tech-black;
    margin-bottom: 4px;
  }

  &__info-primary-copy {
    .font-weight-normal;

    display: block;
    font-size: 13px;
    line-height: 15px;
    color: @tech-black;
    margin-bottom: 20px;
  }

  &__info-form {
    .font-weight-medium;

    display: block;
    font-size: 15px;
    line-height: 18px;
    color: @tech-black;
    float: left;
    width: (100% / 3) * 2;
    padding-right: 50px;

    .v-m-vertical {
      margin: 0px 0px 24px 0px;
    }
  }

  &__info-form label {
    .font-weight-medium;

    display: block;
    font-size: 15px;
    line-height: 18px;
    color: @tech-black;
  }

  &__info-form input.form-control {
    margin-top: 10px;
    height: 35px;
  }

  &__info-block {
    .font-weight-normal;

    display: block;
    font-size: 13px;
    line-height: 17px;
    color: @tech-black;
    float: left;
    width: 100% / 3;
    background-color: @background-white;
    padding: 10px;
  }

  &__email-disclaimer {
    .font-weight-normal;

    display: block;
    font-size: 13px;
    line-height: 16px;
    color: @tech-black;
    margin-top: 5px;
  }

  &__btn-continue {
    .font-weight-medium;

    display: inline-block;
    font-size: 15px;
    line-height: 18px;
    margin-top: 15px;
    width: 50%;
  }
}
