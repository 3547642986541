@media (min-width: 1200px) {
}@media (min-width: 1200px) {
}.price-view-pb {
  version: "pricing-lib-price-19-2513-1";
}
.pricing-lib-price-19-2513-1 .priceView-layout-small.priceView-fixed-height {
  min-height: auto !important;
}
.pricing-lib-price-19-2513-1 .priceView-layout-small.priceView-fixed-height .pricing-price {
  position: initial !important;
}
.pricing-lib-price-19-2513-1 .priceView-layout-small.priceView-fixed-height .priceView-price {
  height: 90px;
  display: table-cell;
  vertical-align: bottom;
}
.pricing-lib-price-19-2513-1 .priceView-layout-mini.priceView-fixed-height .priceView-price {
  height: 34px;
  display: table-cell;
  vertical-align: bottom;
}
.pricing-lib-price-19-2513-1 .priceView-layout-mini.priceView-fixed-height .priceView-price .term-price {
  font-size: 13px;
}
.priceView-align-right {
  text-align: right;
}
.priceView-align-right .priceView-tap-to-view-price {
  text-align: right;
}
:not(priceView-align-right) .priceView-tap-to-view-price {
  text-align: left;
}
.pricing-lib-price-19-2513-1 .single-price {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #1d252c;
  font-weight: 400;
}
.pricing-lib-price-19-2513-1 .single-price.single-bold {
  font-weight: 500;
}
.pricing-lib-price-19-2513-1 .single-price.single-red .priceView-hero-price {
  color: #bb0628;
}
.pricing-lib-price-19-2513-1 .single-price .term-price {
  font-size: 13px;
}
.pricing-lib-price-19-2513-1 .single-price .priceView-hero-price {
  font-size: 13px;
  line-height: 16px;
}
.pricing-lib-price-19-2513-1 .package-price {
  font-weight: 600;
  color: #1d252c;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}
.pricing-lib-price-19-2513-1 .package-price-wrapper {
  display: flex;
}
.pricing-lib-price-19-2513-1 .package-price-wrapper .single-price .priceView-customer-price {
  color: #040c13;
}
.pricing-lib-price-19-2513-1 .bespoke-min-height-reservation {
  min-height: 82px;
}
.pricing-lib-price-19-2513-1 .bespoke-price {
  font-weight: 600;
  color: #1d252c;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 5px;
}
.pricing-lib-price-19-2513-1 .bespoke-price-wrapper {
  display: flex !important;
}
.pricing-lib-price-19-2513-1 .bespoke-price-wrapper .single-price .priceView-customer-price {
  color: #040c13;
}
.restricted-pricing__info-container {
  min-height: 100px;
  overflow: hidden;
}
.restricted-pricing__info-title {
  font-weight: 600;
  display: block;
  font-size: 20px;
  line-height: 24px;
  color: #1d252c;
  margin-bottom: 4px;
}
.restricted-pricing__info-primary-copy {
  font-weight: 400;
  display: block;
  font-size: 13px;
  line-height: 15px;
  color: #1d252c;
  margin-bottom: 20px;
}
.restricted-pricing__info-form {
  font-weight: 600;
  display: block;
  font-size: 15px;
  line-height: 18px;
  color: #1d252c;
  float: left;
  width: 66.66666667%;
  padding-right: 50px;
}
.restricted-pricing__info-form .v-m-vertical {
  margin: 0px 0px 24px 0px;
}
.restricted-pricing__info-form label {
  font-weight: 600;
  display: block;
  font-size: 15px;
  line-height: 18px;
  color: #1d252c;
}
.restricted-pricing__info-form input.form-control {
  margin-top: 10px;
  height: 35px;
}
.restricted-pricing__info-block {
  font-weight: 400;
  display: block;
  font-size: 13px;
  line-height: 17px;
  color: #1d252c;
  float: left;
  width: 33.33333333%;
  background-color: #f0f2f4;
  padding: 10px;
}
.restricted-pricing__email-disclaimer {
  font-weight: 400;
  display: block;
  font-size: 13px;
  line-height: 16px;
  color: #1d252c;
  margin-top: 5px;
}
.restricted-pricing__btn-continue {
  font-weight: 600;
  display: inline-block;
  font-size: 15px;
  line-height: 18px;
  margin-top: 15px;
  width: 50%;
}
.restricted-pricing__price-container {
  min-height: 100px;
  overflow: hidden;
}
.restricted-pricing__heading {
  margin-bottom: 30px;
  color: #1d252c;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.restricted-pricing__heading svg {
  width: 20px;
}
.restricted-pricing__product {
  position: relative;
}
.restricted-pricing__product-divider {
  position: absolute;
  left: 66.66666667%;
  top: -8px;
  background-color: #c5cbd5;
  width: 1px;
  bottom: 8px;
  display: block;
  margin-left: -15px;
}
.restricted-pricing__product-divider:before {
  content: '';
}
.restricted-pricing__product-image {
  max-width: 100%;
  max-height: 170px;
  margin: 0 auto;
  display: block;
}
.restricted-pricing__product-info {
  padding-right: 16px;
  padding-left: 16px;
}
.restricted-pricing__product-name {
  font-weight: 400;
  display: block;
  font-size: 15px;
  line-height: 17px;
  color: #1d252c;
}
.restricted-pricing__product-model {
  padding: 15px 0 7px;
}
.restricted-pricing__product-model-info {
  padding-left: 24px;
}
.restricted-pricing__product-model-label {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #1d252c;
}
.restricted-pricing__product-model-text {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #1d252c;
}
.restricted-pricing__product-rating {
  display: inline-block;
  margin-left: 8px;
  font-size: 11px;
}
.restricted-pricing__product-rating-score {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.restricted-pricing__each-price {
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #1d252c;
}
.restricted-pricing__saving-price {
  font-weight: 600;
  display: inline-block;
  font-size: 13px;
  line-height: 17px;
  padding: 3px 7px;
  margin-right: 8px;
  color: #fff;
  background-color: #bb0628;
}
.restricted-pricing__sale-message {
  margin-top: 10px;
  font-weight: bold;
}
.restricted-pricing__price-block {
  padding-left: 0px;
}
.restricted-pricing__regular-price-section {
  font-weight: 400;
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
}
.restricted-pricing__regular-price {
  padding-left: 4px;
}
.restricted-pricing__go-to-cart-button {
  font-weight: 600;
  width: 100%;
  display: block;
  font-size: 15px;
  line-height: 18px;
  margin-top: 27.5px;
  margin-bottom: 24px;
}
.restricted-pricing__remove-item {
  font-weight: 400;
  display: block;
  font-size: 13px;
  line-height: 15px;
  color: #0457c8;
}
.restricted-pricing__disclaimer1 {
  font-weight: 600;
  display: block;
  font-size: 13px;
  line-height: 15px;
  color: #1d252c;
  padding: 50px 0px 7px 0px;
  margin: 0px;
}
.restricted-pricing__disclaimer2 {
  font-weight: 400;
  display: block;
  font-size: 13px;
  line-height: 15px;
  margin: 0px;
  color: #1d252c;
}
.restricted-pricing-modal-content {
  color: #1d252c;
}
.restricted-pricing-modal-content p {
  margin: 11px 0px;
  color: #1d252c;
  font-size: 13px;
  line-height: 15px;
}
.restricted-pricing-modal-content p strong {
  margin-top: 10px;
  padding-top: 30px;
  display: inline-block;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings-regular-price {
  white-space: nowrap;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .pricing-price__savings-regular-price {
  white-space: normal;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .pricing-price__savings-regular-price {
  white-space: normal;
}
.pricing-lib-price-19-2513-1 .pricing-price__regular-price-content--block {
  display: block;
}
.pricing-lib-price-19-2513-1 .pricing-price__regular-price-content--block-mt {
  margin-top: 4px;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings {
  font-weight: 600;
  display: inline-block;
  letter-spacing: -0.17px;
  margin-right: 4px;
  color: #fff;
  background-color: #bb0628;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .pricing-price__savings {
  font-family: 'Human BBY Digital', sans-serif;
  font-size: 15px;
  line-height: 18px;
  padding: 3px 7px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .pricing-price__savings {
  font-size: 13px;
  line-height: 16px;
  padding: 3px 7px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .pricing-price__savings {
  font-size: 13px;
  line-height: 16px;
  padding: 3px 7px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .pricing-price__savings {
  font-size: 11px;
  line-height: 14px;
  padding: 1.5px 4px;
  margin-right: 5px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .pricing-price__savings {
  font-size: 11px;
  line-height: 14px;
  padding: 1.5px 4px;
  margin-right: 5px;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings .priceView-saving-subscription-units {
  text-transform: lowercase;
  font-size: inherit;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings--promo-red {
  background-color: #e81e25;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings--green {
  background-color: #318000;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings--bg-green {
  background-color: #318000;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings--bg-green-ab {
  background-color: #318000;
  color: #fff;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings--text-red-ab {
  color: #bb0628;
  background-color: #fff;
  letter-spacing: -0.17px;
  padding: 3px 7px 3px 0px;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .pricing-price__savings--text-red-ab {
  padding: 3px 7px 3px 0px;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings--block {
  display: block;
  margin-right: 0;
  padding: 0 !important;
}
.pricing-lib-price-19-2513-1 .pricing-price__savings-alone {
  margin-right: 0;
  letter-spacing: -0.17px;
}
.pricing-lib-price-19-2513-1 .pricing-price__regular-price {
  font-weight: 400;
  display: inline-block;
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .pricing-price__regular-price {
  font-size: 15px;
  line-height: 18px;
  color: #55555a;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .pricing-price__regular-price {
  font-size: 13px;
  line-height: 17px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .pricing-price__regular-price {
  font-size: 13px;
  line-height: 17px;
}
.pricing-lib-price-19-2513-1 .pricing-price__regular-price-description-button {
  margin-left: 5px;
  margin-top: -1px;
  width: 14px !important;
  min-width: 0 !important;
}
.pricing-lib-price-19-2513-1 .pricing-price__regular-price-description-modal {
  text-align: left;
  font-size: 15px;
}
.pricing-lib-price-19-2513-1 .pricing-price__regular-price-description-modal button {
  width: auto !important;
  min-width: 0 !important;
}
.pricing-lib-price-19-2513-1 .pricing-price__regular-price-description-content {
  white-space: normal;
  font-family: 'Human BBY Digital';
}
.pricing-lib-price-19-2513-1 .pricing-price__regular-price-description-learn-more-link {
  margin-top: 20px;
}
.pricing-lib-price-19-2513-1 .priceView-offers {
  min-height: 1em;
}
.pricing-lib-price-19-2513-1 .priceView-offers button {
  border: none;
  padding: 0;
  background: none;
}
.pricing-lib-price-19-2513-1 .priceView-offers .pl-close {
  display: none;
}
.pricing-lib-price-19-2513-1 .priceView-offer {
  width: 151px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-offer {
  height: 12px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-offer {
  height: 12px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-offer {
  height: 12px;
}
.pricing-lib-price-19-2513-1 .priceView-offer-badge {
  font-size: 11px;
  height: 13px;
  letter-spacing: -0.26px;
  font-weight: 400;
  color: #1d252c;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-offer-badge {
  height: 11px;
  font-size: 9px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-offer-badge {
  height: 11px;
  font-size: 9px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-offer-badge {
  height: 11px;
  font-size: 9px;
}
.pricing-lib-price-19-2513-1 .priceView-offer-badge strong {
  font-weight: 700;
}
.pricing-lib-price-19-2513-1 .priceView-my-bby-offer-price {
  text-decoration: none;
}
.pricing-lib-price-19-2513-1 .priceView-my-bby-offer-price:hover {
  text-decoration: underline;
}
.pricing-lib-price-19-2513-1 a {
  text-decoration: none;
}
.pricing-lib-price-19-2513-1 .priceView-offer-early-access {
  background-image: url('../../../lib-price/lib/images/early-access.png');
}
.pricing-lib-price-19-2513-1 .priceView-offer-gamers-club-unlocked {
  height: 21px;
  background-image: url('../../../lib-price/lib/images/gamers-club-unlocked.png');
}
.pricing-lib-price-19-2513-1 .priceView-offer-gamers-club {
  background-image: url('../../../lib-price/lib/images/gamers-club.png');
}
.pricing-lib-price-19-2513-1 .priceView-offer-loyalty {
  background-image: url('../../../lib-price/lib/images/mybby-loyalty.png');
}
.pricing-lib-price-19-2513-1 .priceView-offer-elite-loyalty {
  background-image: url('../../../lib-price/lib/images/mybby-loyalty-elite.png');
}
.pricing-lib-price-19-2513-1 .priceView-offer-icon-my-bby-member {
  background-image: url('../../../lib-price/lib/images/MyBBY_OfferPricing.png');
}
.pricing-lib-price-19-2513-1 .priceView-offer-elite-plus-loyalty {
  background-image: url('../../../lib-price/lib/images/mybby-loyalty-elite-plus.png');
}
.pricing-lib-price-19-2513-1 .icon-my-bby-offer {
  background-image: url('../../../lib-price/lib/images/MyBBY_MemberOffer.png');
  height: 18px;
  width: 94px;
}
.pricing-lib-price-19-2513-1 .icon-total-tech-support-priceView {
  background-image: url('../../../lib-price/lib/images/total-tech-support-thumb.png');
  height: 13px;
  width: 126px;
  background-size: cover;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .pricing-lib-price-19-2513-1 .priceView-offer-early-access {
    background-image: url('../../../lib-price/lib/images/early-access-2x.png');
  }
  .pricing-lib-price-19-2513-1 .priceView-offer-gamers-club-unlocked {
    height: 21px;
    background-image: url('../../../lib-price/lib/images/gamers-club-unlocked-2x.png');
  }
  .pricing-lib-price-19-2513-1 .priceView-offer-gamers-club {
    background-image: url('../../../lib-price/lib/images/gamers-club-2x.png');
  }
  .pricing-lib-price-19-2513-1 .priceView-offer-loyalty {
    background-image: url('../../../lib-price/lib/images/mybby-loyalty-2x.png');
  }
  .pricing-lib-price-19-2513-1 .priceView-offer-elite-loyalty {
    background-image: url('../../../lib-price/lib/images/mybby-loyalty-elite-2x.png');
  }
  .pricing-lib-price-19-2513-1 .priceView-offer-elite-plus-loyalty {
    background-image: url('../../../lib-price/lib/images/mybby-loyalty-elite-plus-2x.png');
  }
  .pricing-lib-price-19-2513-1 .priceView-offer-icon-my-bby-member {
    background-image: url('../../../lib-price/lib/images/MyBBY_OfferPricing_2x.png');
  }
  .pricing-lib-price-19-2513-1 .icon-my-bby-offer {
    background-image: url('../../../lib-price/lib/images/MyBBY_MemberOffer_2x.png');
    height: 18px !important;
    width: 94px !important;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .pricing-lib-price-19-2513-1 .icon-total-tech-support-priceView {
    background-image: url('../../../lib-price/lib/images/total-tech-support-thumb-2x.png');
    height: 13px !important;
    width: 126px !important;
    background-size: cover;
  }
}
.pricing-lib-price-19-2513-1 .priceView-offer-overlay {
  font-size: 13px;
}
.pricing-lib-price-19-2513-1 .priceView-offer-overlay p:last-child {
  margin-bottom: 0;
}
.pricing-lib-price-19-2513-1 .priceView-offer-overlay-headline {
  margin-bottom: 15px;
  font-size: 15px;
}
.pricing-lib-price-19-2513-1 .priceView-offer-overlay-disclaimer {
  margin-top: 15px;
}
.pricing-lib-price-19-2513-1 .priceView-offer-business-Only-Price {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #1d252c;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-offer-business-Only-Price {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-offer-business-Only-Price {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-offer-business-Only-Price {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-offer-business-Only-Price {
  font-size: 11px;
  line-height: 14px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-offer-business-Only-Price {
  font-size: 11px;
  line-height: 14px;
}
.pricing-lib-price-19-2513-1 .priceView-my-bby-offer-price-block {
  display: block;
}
.pricing-lib-price-19-2513-1 .priceView-my-bby-offer {
  display: inline-block;
  padding-left: 2px;
  margin-bottom: 5px;
  margin-top: 8px;
}
.pricing-lib-price-19-2513-1 .priceView-my-bby-offer .icon {
  display: inline-block;
  vertical-align: middle;
}
.pricing-lib-price-19-2513-1 .pricing-price .price-box {
  display: flex;
}
.pricing-lib-price-19-2513-1 .pricing-price__sale-message {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #1d252c;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .pricing-price__sale-message {
  font-size: 15px;
  line-height: 19px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .pricing-price__sale-message {
  font-size: 15px;
  line-height: 19px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .pricing-price__sale-message {
  font-size: 15px;
  line-height: 19px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .pricing-price__sale-message {
  font-size: 15px;
  line-height: 19px;
  float: left;
  padding-right: 3px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .pricing-price__sale-message {
  font-size: 15px;
  line-height: 19px;
  float: left;
  padding-right: 3px;
}
.pricing-lib-price-19-2513-1 .pricing-price__sale-message-mini {
  font-weight: 600;
  color: #1d252c;
  font-size: 15px;
  line-height: 19px;
  padding-right: 3px;
}
.pricing-lib-price-19-2513-1 .restricted-price-previous-price {
  font-family: "Human BBY Digital", "Human BBY Web", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-size: 25px;
  line-height: 29px;
  text-decoration: line-through;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout {
  font-weight: 400;
  border: none;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  font-size: 13px;
  line-height: 16px;
  color: #0046be;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout {
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout {
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
}
.pricing-lib-price-19-2513-1 .priceView-minimum-advertised-price {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: transparent;
  font-size: 13px;
  line-height: 16px;
  color: #0046be;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-minimum-advertised-price {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-minimum-advertised-price {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-minimum-advertised-price {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-minimum-advertised-price {
  font-size: 13px;
  line-height: 16px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-minimum-advertised-price {
  font-size: 13px;
  line-height: 16px;
}
.pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout-link {
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: transparent;
  font-size: 13px;
  line-height: 16px;
  color: #0046be;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout-link {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout-link {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout-link {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout-link {
  font-size: 13px;
  line-height: 16px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-restricted-price-checkout-link {
  font-size: 13px;
  line-height: 16px;
}
.pricing-lib-price-19-2513-1 .priceView-tap-to-view-price {
  border: none;
  color: #0457c8;
  padding: 0px;
  background-color: transparent;
}
.pricing-lib-price-19-2513-1 .priceView-tap-to-view-price .price-box {
  display: flex;
}
.pricing-lib-price-19-2513-1 .priceView-tap-to-view-price-bold {
  font-weight: 500;
}
.pricing-lib-price-19-2513-1 .priceView-tap-to-view-price-icr {
  font-family: "Human BBY Digital", "Human BBY Web", Arial, Helvetica, sans-serif;
  color: #040C13;
}
.pricing-lib-price-19-2513-1 .priceView-price-disclaimer {
  font-weight: 400;
  margin-bottom: 2px;
  font-size: 15px;
  line-height: 18px;
  color: #1d252c;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-price-disclaimer {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-price-disclaimer {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-price-disclaimer {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-price-disclaimer {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.6px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-price-disclaimer {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.6px;
}
.pricing-lib-price-19-2513-1 .priceView-price-disclaimer__activation {
  display: inline-block;
  padding-right: 5px;
  font-size: inherit;
}
.pricing-lib-price-19-2513-1 .price-view-pb {
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-free {
  font-weight: bold;
  text-transform: uppercase;
}
.pricing-lib-price-19-2513-1 .priceView-price-prefix {
  margin-bottom: 0px;
}
.pricing-lib-price-19-2513-1 .priceView-hero-price {
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  color: #1d252c;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-hero-price {
  font-size: 30px;
  line-height: 35px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-hero-price {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-hero-price {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-hero-price {
  font-size: 15px;
  line-height: 18px;
  vertical-align: middle;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-hero-price {
  font-size: 15px;
  line-height: 18px;
  vertical-align: middle;
}
.priceView-layout-single .pricing-lib-price-19-2513-1 .priceView-hero-price {
  font-size: 13px;
  line-height: 16px;
}
.priceView-single-price .pricing-lib-price-19-2513-1 .priceView-hero-price {
  font-size: 13px;
  line-height: 16px;
}
.pricing-lib-price-19-2513-1 .single-price .priceView-hero-price {
  font-weight: 400;
}
.pricing-lib-price-19-2513-1 .single-price.single-bold .priceView-hero-price {
  font-weight: 500;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-units {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #1d252c;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-total-tech-units {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-total-tech-units {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-total-tech-units {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-total-tech-units {
  font-size: 11px;
  line-height: 14px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-total-tech-units {
  font-size: 11px;
  line-height: 14px;
}
.pricing-lib-price-19-2513-1 .priceView-price-match-banner {
  margin: 20px 0;
  color: #0046be;
}
.pricing-lib-price-19-2513-1 .priceView-price-match-banner .icon-Circle_Dollar {
  font-size: 23px;
  padding-right: 4px;
}
.pricing-lib-price-19-2513-1 .priceView-offer-wrapper {
  display: block;
  position: relative;
}
.pricing-lib-price-19-2513-1 .priceView-down-payment-message {
  margin-top: 10px;
}
.pricing-lib-price-19-2513-1 .priceView-carrier-bill-disclaimer {
  margin-top: 15px;
  font-size: 11px;
}
.pricing-lib-price-19-2513-1 .priceview-expiration-timer {
  margin-top: 4px;
  margin-bottom: 8px;
}
.priceView-layout-small .priceView-previous-price {
  font-size: 20px;
  line-height: 20px;
}
.priceView-layout-mini .priceView-mini-price-red .pricing-price__sale-message,
.priceView-layout-mini .priceView-restricted-price-red .pricing-price__sale-message,
.priceView-layout-mini .priceView-mini-price-red .priceView-hero-price,
.priceView-layout-mini .priceView-restricted-price-red .priceView-hero-price {
  color: #bb0628;
}
.priceView-layout-mini .priceView-hero-price {
  font-size: 15px;
  line-height: 19px;
}
.priceView-layout-mini .priceView-subscription-units {
  font-size: 15px;
  line-height: 19px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo {
  margin-top: 16px;
  padding: 0px 10px 10px 10px !important;
  border: 1px solid #c5cbd5;
  border-radius: 4px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__member-badge {
  margin-top: 10px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__offer-value {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1d252c;
  margin-top: 10px;
  margin-right: 2px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__offer-value2 {
  margin: 10px 2px 8px 0;
  display: inline-block;
  letter-spacing: -0.26px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__offer-value2 strong {
  font-weight: 700;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__apply-offer {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1d252c;
  display: inline-block;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__apply-offer .checkbox {
  display: inline;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__apply-offer label {
  padding: 0px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__check-offer-details {
  display: inline-block;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-cabo__expire-disclaimer {
  color: #bb0628;
  font-size: 12px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo .help-block {
  display: none !important;
}
.pricing-lib-price-19-2513-1 .priceView-cabo .v-m-vertical {
  margin-bottom: 0px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__header {
  margin: 0 0 8px 0;
  border-bottom: 1px solid #c5cbd5;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__header-bby-offer {
  font-weight: 400;
  font-size: 20px;
  font-weight: 500;
  color: #1d252c;
  letter-spacing: 0;
  text-align: left;
  display: inline-block;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__header-bby-offer .icon {
  background-image: url('../../../lib-price/lib/images/my-bby-stacked-svg-65350.svg') !important;
  background-size: cover;
  height: 24px !important;
  width: 78px !important;
  margin-right: 4px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__header-OFFER {
  vertical-align: top;
  padding-top: 3px;
  font-size: 12px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__disclaimer {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #55555a;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__disclaimer1 {
  font-weight: 500;
  font-size: 13px;
  padding-bottom: 8px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__disclaimer2 {
  line-height: 13px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__description {
  display: inline-block;
  padding: 20px 0;
  width: 100%;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__effectiveDate {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1d252c;
  padding-top: 15px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__offer-image-wrapper {
  margin-right: 10px;
  width: 45%;
  float: left;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__body {
  width: 50%;
  float: left;
  margin-top: 25px;
  min-width: 170px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__image {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__default-image {
  background-image: url('../../../lib-price/lib/images/mybby-offer-default.svg') !important;
  height: 185px !important;
  background-repeat: no-repeat;
  background-size: contain;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__headline {
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
}
.pricing-lib-price-19-2513-1 .priceView-cabo-offer-detail__copy {
  padding-top: 15px;
}
.pricing-lib-price-19-2513-1 .priceView-suco {
  margin-top: 16px;
  border-radius: 4px;
}
.pricing-lib-price-19-2513-1 .priceView-suco__offer-details {
  display: flex;
  align-items: center;
}
.pricing-lib-price-19-2513-1 .priceView-suco__offer-applied {
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-suco__apply-suco-checkbox {
  float: left;
}
.pricing-lib-price-19-2513-1 .priceView-suco__check-applied svg {
  width: 16px;
  height: 16px;
  fill: #1d252c;
  margin-right: 5px;
  vertical-align: bottom;
}
.pricing-lib-price-19-2513-1 .priceView-suco__member-badge {
  margin-top: 10px;
}
.pricing-lib-price-19-2513-1 .priceView-suco__offer-value {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  display: inline-block;
}
.pricing-lib-price-19-2513-1 .priceView-suco__offer-value-medium {
  color: #0457C8;
}
.pricing-lib-price-19-2513-1 .priceView-suco__offer-value2 {
  margin: 10px 2px 8px 0;
  display: inline-block;
  letter-spacing: -0.26px;
}
.pricing-lib-price-19-2513-1 .priceView-suco__offer-value2 strong {
  font-weight: 700;
}
.pricing-lib-price-19-2513-1 .priceView-suco__apply-offer {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1d252c;
  display: inline-block;
}
.pricing-lib-price-19-2513-1 .priceView-suco__apply-offer .checkbox {
  display: inline;
}
.pricing-lib-price-19-2513-1 .priceView-suco__apply-offer label {
  padding: 0px;
}
.pricing-lib-price-19-2513-1 .priceView-suco__check-offer-details {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-suco__expire-disclaimer {
  color: #bb0628;
  font-size: 12px;
}
.pricing-lib-price-19-2513-1 .priceView-suco__border {
  padding: 10px;
  border-width: 1px;
  border-color: #c5cbd5;
  border-radius: 4px;
}
.pricing-lib-price-19-2513-1 .priceView-suco__border-solid {
  border-style: solid;
}
.pricing-lib-price-19-2513-1 .priceView-suco__border-dashed {
  border-style: dashed;
}
.pricing-lib-price-19-2513-1 .priceView-suco .help-block {
  display: none !important;
}
.pricing-lib-price-19-2513-1 .priceView-suco .v-m-vertical {
  margin-bottom: 0px;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__header {
  margin: 0 0 8px 0;
  border-bottom: 1px solid #c5cbd5;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__header-bby-offer {
  font-weight: 400;
  font-size: 20px;
  font-weight: 500!important;
  line-height: 24px;
  color: #1d252c;
  letter-spacing: 0;
  text-align: left;
  display: inline-block;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__header-bby-offer .icon {
  background-image: url('../../../lib-price/lib/images/my-bby-stacked-svg-65350.svg') !important;
  background-size: cover;
  height: 24px !important;
  width: 78px !important;
  margin-right: 4px;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__header-OFFER {
  vertical-align: top;
  padding-top: 3px;
  font-size: 12px;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__disclaimer {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__disclaimer1 {
  font-weight: 500;
  padding-bottom: 8px;
  font-size: 13px;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__disclaimer2 {
  line-height: 13px;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__description {
  display: inline-block;
  padding: 20px 0;
  width: 100%;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__effectiveDate {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #1d252c;
  padding-top: 15px;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__offer-image-wrapper {
  margin-right: 10px;
  width: 45%;
  float: left;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__body {
  width: 50%;
  float: left;
  margin-top: 25px;
  min-width: 170px;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__image {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__default-image {
  background-image: url('../../../lib-price/lib/images/mybby-offer-default.svg') !important;
  height: 185px !important;
  background-repeat: no-repeat;
  background-size: contain;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__headline {
  font-weight: 600;
  font-size: 17px;
  line-height: 18px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-suco-offer-detail__copy {
  font-size: 15px;
  padding-top: 15px!important;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount {
  margin-top: 8px;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .priceview-best-buy-text {
  font-weight: 600;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .apply-offer {
  margin-top: 16px;
  padding: 5px 10px !important;
  border: 1px #c5cbd5;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .apply-offer--solid {
  border-style: solid;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .apply-offer--dashed {
  border-style: dashed;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .apply-offer__checkbox {
  display: inline-block;
  margin: auto 0;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .apply-offer__label {
  font-size: 15px;
  vertical-align: middle;
  margin: auto 0;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .valid-dates-text {
  display: inline-block;
  font-size: 11px;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .valid-dates-text--red-text {
  color: #BB0628;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .valid-dates-text--promo-red-text {
  color: #E81E25;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .details-link {
  display: inline-block;
  font-size: 11px;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .save-text {
  font-size: 13px;
  line-height: 18px;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .save-text--small-view {
  font-size: 11px;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .save-text__checkmark {
  height: 16px;
  vertical-align: top;
}
.pricing-lib-price-19-2513-1 .customer-selected-discount .save-text__amount {
  font-weight: 500;
}
@keyframes pricing-price-strikethrough {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.pricing-lib-price-19-2513-1 .priceView-previous-price {
  font-weight: 600;
  display: inline-block;
  position: relative;
  text-decoration: line-through;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-previous-price {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-previous-price {
  font-size: 20px;
  line-height: 24px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-previous-price {
  font-size: 20px;
  line-height: 21px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-previous-price {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-previous-price {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
}
.pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-subscription-units {
  font-size: 20px;
  line-height: 24px;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-subscription-units {
  font-size: 25px;
  line-height: 24px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-subscription-units {
  font-size: 20px;
  line-height: 20px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-subscription-units {
  font-size: 20px;
  line-height: 20px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-subscription-units {
  font-size: 15px;
  line-height: 18px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-subscription-units {
  font-size: 15px;
  line-height: 18px;
}
.pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-total-tech-units {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-total-tech-units {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-total-tech-units {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-total-tech-units {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-total-tech-units {
  font-size: 11px;
  line-height: 14px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-previous-price .priceView-total-tech-units {
  font-size: 11px;
  line-height: 14px;
}
.pricing-lib-price-19-2513-1.priceView-dynamic-price .priceView-previous-price {
  text-decoration: none;
}
.pricing-lib-price-19-2513-1.priceView-dynamic-price .priceView-previous-price::after {
  content: ' ';
  position: absolute;
  top: 49%;
  left: 0;
  width: 100%;
  height: 2px;
  background: #1d252c;
  animation-name: pricing-price-strikethrough;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.pricing-lib-price-19-2513-1 .priceView-customer-price {
  font-weight: 600;
  font-weight: 500;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-customer-price {
  font-size: 30px;
  line-height: 35px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-customer-price {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-customer-price {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-customer-price {
  font-size: 15px;
  line-height: 18px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-customer-price {
  font-size: 15px;
  line-height: 18px;
}
.pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-subscription-units {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-subscription-units {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-subscription-units {
  font-size: 20px;
  line-height: 24px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-subscription-units {
  font-size: 20px;
  line-height: 24px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-subscription-units {
  font-size: 15px;
  line-height: 18px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-subscription-units {
  font-size: 15px;
  line-height: 18px;
}
.pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-total-tech-units {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-total-tech-units {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-total-tech-units {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-total-tech-units {
  font-size: 13px;
  line-height: 16px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-total-tech-units {
  font-size: 11px;
  line-height: 14px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-customer-price .priceView-total-tech-units {
  font-size: 11px;
  line-height: 14px;
}
.disclaimer-collapse {
  margin-top: 12px;
  font-size: 11px;
  font-family: "Human BBY Digital", "Human BBY Web", Arial, Helvetica, sans-serif;
}
.disclaimer-collapse__trigger {
  color: #0457c8;
  margin-bottom: 4px;
}
.disclaimer-collapse__trigger-arrow {
  margin: 0 4px;
  height: 11px;
  width: 11px;
  transition: all 0.1s;
}
.disclaimer-collapse__content {
  display: none;
  color: #55555A;
}
.disclaimer-collapse--expanded .disclaimer-collapse__trigger-arrow {
  transform: rotate(180deg);
}
.disclaimer-collapse--expanded .disclaimer-collapse__content {
  display: block;
}
.disclaimer-collapse--small-view {
  margin-top: 20px;
}
.priceView .priceView-bundle-and-save .modal-content,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save .modal-content {
  margin: 0px, 36px, 0px, 59px;
}
.priceView .priceView-bundle-and-save .offer-icon-modal,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save .offer-icon-modal {
  width: 24px;
  height: 24px;
  vertical-align: bottom;
  background: url('../../../lib-price/lib/images/offer-icon-modal.svg') no-repeat center;
  background-size: contain;
  display: inline-block;
}
.priceView .priceView-bundle-and-save .priceView-anchor,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save .priceView-anchor {
  position: relative;
}
.priceView .priceView-bundle-and-save__modal-add-to-cart-wrapper,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__modal-add-to-cart-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
}
.priceView .priceView-bundle-and-save__modal-add-to-cart,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__modal-add-to-cart {
  width: 175px;
}
.priceView .priceView-bundle-and-save__link,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__link {
  border: none;
  padding: 0;
  background-color: transparent;
  line-height: 14px;
}
.priceView .priceView-bundle-and-save__priceBlock-banner,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner {
  font-weight: 600;
  white-space: normal !important;
  margin: 0;
  color: #0046be;
  white-space: nowrap;
}
.priceView .priceView-bundle-and-save__priceBlock-banner .offer-icon,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner .offer-icon {
  margin-right: 4px;
}
.priceView .priceView-bundle-and-save__priceBlock-banner .font-weight-normal,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner .font-weight-normal {
  font-weight: 400;
}
.priceView .priceView-bundle-and-save__priceBlock-banner .is-mini,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner .is-mini {
  font-size: 13px;
}
.priceView-layout-large .priceView .priceView-bundle-and-save__priceBlock-banner,
.priceView-layout-large .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner {
  font-size: 13px;
  line-height: 17px;
}
.priceView-layout-medium .priceView .priceView-bundle-and-save__priceBlock-banner,
.priceView-layout-medium .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner {
  font-size: 13px;
  line-height: 17px;
}
.priceView-layout-small .priceView .priceView-bundle-and-save__priceBlock-banner,
.priceView-layout-small .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner {
  font-size: 13px;
  line-height: 17px;
}
.priceView-layout-mini .priceView .priceView-bundle-and-save__priceBlock-banner,
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner {
  font-size: 11px;
  line-height: 14px;
}
.priceView-layout-mini .priceView .priceView-bundle-and-save__priceBlock-banner .offer-icon,
.priceView-layout-mini .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner .offer-icon {
  width: 14px;
  height: 14px;
}
.priceView-mini-price .priceView .priceView-bundle-and-save__priceBlock-banner,
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner {
  font-size: 11px;
  line-height: 14px;
}
.priceView-mini-price .priceView .priceView-bundle-and-save__priceBlock-banner .offer-icon,
.priceView-mini-price .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner .offer-icon {
  width: 14px;
  height: 14px;
}
.priceView .priceView-bundle-and-save__priceBlock-banner .ficon-special-offers,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner .ficon-special-offers {
  vertical-align: middle !important;
  color: #0046be !important;
}
.priceView .priceView-bundle-and-save__priceBlock-banner .ficon-bby-points,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner .ficon-bby-points {
  color: #0046be !important;
}
.priceView .priceView-bundle-and-save__priceBlock-banner .offer-icon,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__priceBlock-banner .offer-icon {
  width: 17px;
  height: 17px;
  vertical-align: bottom;
  background: url('../../../lib-price/lib/images/offer-icon.svg') no-repeat center;
  background-size: contain;
  display: inline-block;
}
.priceView .priceView-bundle-and-save__banner,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__banner {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.priceView .priceView-bundle-and-save__banner .ficon-special-offers,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__banner .ficon-special-offers {
  font-size: 27px !important;
  line-height: 17px;
  color: #0046be !important;
}
.priceView .priceView-bundle-and-save__banner .ficon-bby-points,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__banner .ficon-bby-points {
  font-size: 17px !important;
  line-height: 20px;
  color: #0046be !important;
}
.priceView .priceView-bundle-and-save__items-in-cart-label,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__items-in-cart-label {
  font-family: "Human BBY Digital", "Human BBY Web", Arial, Helvetica, sans-serif;
  color: #1D252C;
  font-style: italic;
  padding-top: 24px;
  margin-left: 5px;
  display: block;
  font-size: 13px;
  line-height: 16px;
}
.priceView .priceView-bundle-and-save__savings,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__savings {
  padding-top: 4px;
  padding-bottom: 15px;
  font-weight: 400;
  color: #040C13;
  font-size: 15px;
  line-height: 16px;
}
.priceView .priceView-bundle-and-save__list,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__list {
  padding: 0;
  margin: 10px 0 0;
  list-style: none;
  overflow: hidden;
}
.priceView .priceView-bundle-and-save__item:not(:first-of-type),
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__item:not(:first-of-type) {
  padding-top: 10px;
}
.priceView .priceView-bundle-and-save__item + .priceView .priceView-bundle-and-save__item,
.priceView .priceView-bundle-and-save__item + .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__item,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__item + .priceView .priceView-bundle-and-save__item,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__item + .pricing-lib-price-19-2513-1 .priceView-bundle-and-save__item {
  margin-top: 25px;
}
.priceView .priceView-bundle-and-save__product,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__product {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.priceView .priceView-bundle-and-save__image-container,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__image-container {
  flex-basis: 42px;
  display: flex;
  justify-content: center;
}
.priceView .priceView-bundle-and-save__image-wrapper,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__image-wrapper {
  height: 42px;
  width: 74px;
  display: block;
  text-align: center;
}
.priceView .priceView-bundle-and-save__image,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__image {
  max-height: 42px;
  max-width: 74px;
}
.priceView .priceView-bundle-and-save__name,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__name {
  float: left;
  flex: auto;
  margin-right: 34px;
  max-width: 240px;
  overflow: hidden;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1D252C;
}
.priceView .priceView-bundle-and-save__name-sv,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__name-sv {
  float: left;
  flex: auto;
  margin: 0 24px 0 12px;
  width: 161px;
  overflow: hidden;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1D252C;
}
.priceView .priceView-bundle-and-save__price,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__price {
  float: right;
  text-align: right;
}
.priceView .priceView-bundle-and-save__previous-price,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__previous-price,
.priceView .priceView-bundle-and-save__purchase-price,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__purchase-price {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  color: #1D252C;
  float: left;
  text-transform: uppercase;
}
.priceView .priceView-bundle-and-save__previous-price,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__previous-price {
  margin: 0;
  font-size: 15px;
  line-height: 17px;
  display: inline-block;
  position: relative;
  color: #040C13;
}
.priceView .priceView-bundle-and-save__previous-price::after,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save__previous-price::after {
  content: ' ';
  position: absolute;
  top: 0.6em;
  left: 0;
  width: 100%;
  height: 1px;
  background: #040C13;
}
.priceView .priceView-bundle-and-save:focus,
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save:focus {
  outline: none;
}
.priceView .priceView-overlay,
.pricing-lib-price-19-2513-1 .priceView-overlay {
  width: 320px;
  padding: 15px 15px 5px;
  position: relative;
  background: #fff;
  outline: none;
}
.priceView .priceView-overlay .offer-icon,
.pricing-lib-price-19-2513-1 .priceView-overlay .offer-icon {
  width: 20px;
  height: 20px;
  vertical-align: bottom;
  background: url('../../../lib-price/lib/images/offer-icon.svg') no-repeat center;
  background-size: contain;
  display: inline-block;
}
.priceView .priceView-overlay .spinner,
.pricing-lib-price-19-2513-1 .priceView-overlay .spinner {
  display: block;
  margin: 15px auto 0;
}
@media only screen and (min-width: 576px) {
  .priceView .priceView-overlay,
  .pricing-lib-price-19-2513-1 .priceView-overlay {
    border: 1px solid #c5cbd5;
    border-radius: 4px;
    margin-right: 15px;
    position: absolute;
    left: -335px;
    top: -40px;
    -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  }
  .priceView .priceView-overlay-arrow,
  .pricing-lib-price-19-2513-1 .priceView-overlay-arrow {
    display: block;
    width: 0;
    height: 0;
    border-width: 11px;
    border-style: solid;
    border-color: transparent;
    border-left-color: #c5cbd5;
    position: absolute;
    top: 46px;
    right: -22px;
  }
  .priceView .priceView-overlay-arrow::after,
  .pricing-lib-price-19-2513-1 .priceView-overlay-arrow::after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-width: 10px;
    border-right-width: 0;
    border-style: solid;
    border-color: transparent;
    border-left-color: #fff;
    margin-left: -12px;
    position: absolute;
    top: -10px;
  }
  .priceView .priceView-overlay-close-button,
  .pricing-lib-price-19-2513-1 .priceView-overlay-close-button {
    display: none;
  }
}
@media only screen and (max-width: 575px) {
  .priceView .priceView-overlay-wrapper,
  .pricing-lib-price-19-2513-1 .priceView-overlay-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(35, 31, 32, 0.65);
  }
  .priceView .priceView-overlay,
  .pricing-lib-price-19-2513-1 .priceView-overlay {
    margin: 60px auto 0;
  }
  .priceView .priceView-overlay-arrow,
  .pricing-lib-price-19-2513-1 .priceView-overlay-arrow {
    display: none;
  }
  .priceView .priceView-overlay-close-button,
  .pricing-lib-price-19-2513-1 .priceView-overlay-close-button {
    color: #0046be;
    position: absolute;
    top: 10px;
    right: 10px;
    -webkit-appearance: none;
    padding: 0;
    cursor: pointer;
    background: 0 0;
    border: 0;
    font-size: 19.5px;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
  }
}
.pricing-lib-price-19-2513-1 .priceView-bundle-and-save-disclaimer__modal-section .c-overlay-fullscreen {
  z-index: 9002;
}
.pricing-lib-price-19-2513-1 .open-box-option {
  display: block;
}
.pricing-lib-price-19-2513-1 .open-box-option a:hover span {
  text-decoration: none;
}
.pricing-lib-price-19-2513-1 .open-box-option__button {
  text-align: left !important;
}
.pricing-lib-price-19-2513-1 .open-box-option__label {
  font-weight: 600;
  display: inline-block;
  font-size: 13px;
  line-height: 17px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .open-box-option__label-medium {
  font-weight: 400;
  font-weight: 500;
  font-size: 13px;
  color: #1d252c;
  letter-spacing: -0.2px;
}
.pricing-lib-price-19-2513-1 .open-box-option__link {
  font-weight: 400;
  white-space: nowrap;
  font-size: 13px;
  line-height: 17px;
  color: #0457c8;
}
.pricing-lib-price-19-2513-1 .open-box-option__link-medium {
  font-weight: 400;
  font-size: 13px;
  color: #0457c8;
  letter-spacing: -0.4px;
}
.pricing-lib-price-19-2513-1 .open-box-option__label-medium-sv {
  font-weight: 400;
  font-weight: 500;
  font-size: 11px;
  color: #1d252c;
  letter-spacing: -0.2px;
}
.pricing-lib-price-19-2513-1 .open-box-option__link-medium-sv {
  font-weight: 400;
  font-size: 11px;
  color: #0457c8;
  letter-spacing: -0.4px;
}
.pricing-lib-price-19-2513-1 .priceView-open-box-title {
  font-weight: 600;
  font-size: 15px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-open-box-see-all-buying-options {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c5cbd5;
}
.pricing-lib-price-19-2513-1 .priceView-open-box-price .priceView-open-box-on-sale {
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}
.pricing-lib-price-19-2513-1 .priceView-open-box-price .open-box-lowest-price {
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__label {
  font-weight: 600;
  display: inline-block;
  color: #1d252c;
  font-size: 13px;
  line-height: 17px;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__label-medium {
  font-weight: 400;
  font-weight: 500;
  font-size: 13px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__label-medium-sv {
  font-weight: 400;
  font-weight: 500;
  font-size: 11px;
  color: #1d252c;
  letter-spacing: -0.2px;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__price {
  font-weight: 400;
  white-space: nowrap;
  font-size: 13px;
  line-height: 17px;
  color: #0046be;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__price-medium {
  font-weight: 400;
  font-size: 13px;
  color: #0046be;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__price-medium-sv {
  font-weight: 400;
  font-size: 11px;
  color: #0046be;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__unavailable {
  font-weight: 400;
  white-space: nowrap;
  font-size: 13px;
  line-height: 17px;
  color: #55555a;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__unavailable-medium {
  font-weight: 400;
  font-size: 13px;
  color: #55555a;
}
.pricing-lib-price-19-2513-1 .priceView-buy-new-option__unavailable-medium-sv {
  font-weight: 400;
  font-size: 11px;
  color: #55555a;
}
.pricing-lib-price-19-2513-1 .XboxAllAccess__monthlypayment {
  font-weight: 500;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .XboxAllAccess__monthlypayment {
  font-size: 30px;
  line-height: 35px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .XboxAllAccess__monthlypayment {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .XboxAllAccess__monthlypayment {
  font-size: 25px;
  line-height: 29px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .XboxAllAccess__monthlypayment {
  font-size: 15px;
  line-height: 18px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .XboxAllAccess__monthlypayment {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-single .pricing-lib-price-19-2513-1 .XboxAllAccess__monthlypayment {
  font-size: 13px;
  line-height: 16px;
}
.priceView-single-price .pricing-lib-price-19-2513-1 .XboxAllAccess__monthlypayment {
  font-size: 13px;
  line-height: 16px;
}
.pricing-lib-price-19-2513-1 .XboxAllAccess__finance-terms {
  font-size: 15px;
  font-weight: 18px;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .XboxAllAccess__finance-terms {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .XboxAllAccess__finance-terms {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .XboxAllAccess__finance-terms {
  font-size: 15px;
  line-height: 18px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .XboxAllAccess__finance-terms {
  font-size: 11px;
  line-height: 13px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .XboxAllAccess__finance-terms {
  font-size: 11px;
  line-height: 13px;
}
.pricing-lib-price-19-2513-1 .XboxAllAccess__payment-terms {
  font-size: 11px;
  line-height: 13px;
  float: left;
}
.priceView-layout-large .pricing-lib-price-19-2513-1 .XboxAllAccess__offer-details {
  display: inline;
  font-size: 11px;
  line-height: 13px;
  padding: 0;
  height: auto;
  vertical-align: baseline;
  margin-left: 5px;
}
.priceView-layout-medium .pricing-lib-price-19-2513-1 .XboxAllAccess__offer-details {
  font-size: 13px;
  line-height: 16px;
  margin-top: 16px;
}
.priceView-layout-small .pricing-lib-price-19-2513-1 .XboxAllAccess__offer-details {
  font-size: 13px;
  line-height: 16px;
  margin-top: 16px;
}
.priceView-layout-mini .pricing-lib-price-19-2513-1 .XboxAllAccess__offer-details {
  font-size: 15px;
  line-height: 18px;
  margin-top: 22px;
}
.priceView-mini-price .pricing-lib-price-19-2513-1 .XboxAllAccess__offer-details {
  font-size: 15px;
  line-height: 18px;
  margin-top: 22px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition {
  color: #1d252c;
  font-weight: 400;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition.small-view {
  padding: 10px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition.small-view .main-heading,
.pricing-lib-price-19-2513-1 .XBox-terms-condition.small-view .sub-title {
  text-align: left;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition.small-view .modal-terms {
  display: block;
  margin-top: 30px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition.small-view .modal-terms .modal-terms-container {
  width: 100%;
  margin: 0;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .main-heading {
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 20px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .sub-title {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 10px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .sub-heading {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin: 15px 0 20px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .check-list-svg {
  width: 45px;
  height: 45px;
  margin-bottom: 10px;
  background: url('../../../lib-price/lib/images/checklist-icon.svg') no-repeat center;
  background-size: contain;
  display: inline-block;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .cart-svg {
  width: 45px;
  height: 45px;
  margin-bottom: 10px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .content {
  font-size: 13px;
  line-height: 16px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .modal-terms {
  display: flex;
  margin-bottom: 30px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .modal-terms .modal-terms-container {
  width: 50%;
  margin: 10px 20px;
}
.pricing-lib-price-19-2513-1 .XBox-terms-condition .disclaimer {
  font-size: 13px;
  line-height: 16px;
  margin-top: 30px;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content {
  display: flex;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content .c-overlay-fullscreen {
  cursor: default;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__divider {
  border-left: 1px solid #000;
  position: relative;
  padding: 13px 0;
  display: inline-block;
  margin: 7px 10px 0 10px;
  height: 100%;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__divider--percent-margin {
  margin: 8%;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__divider span {
  background: #fff;
  padding: 0px 0;
  font-size: 9px;
  position: absolute;
  top: 7px;
  left: -7px;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__monthly-payment {
  font-weight: 600;
  font-size: 25px;
  margin-top: 5px;
  font-weight: 500;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__monthly-payment strong {
  font-weight: 500;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__monthly-payment span {
  font-size: 15px;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__upgrade-plus {
  margin-top: 0;
  font-size: inherit;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__upgrade-plus strong {
  font-size: 15px;
  line-height: 18px;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__finance-message {
  font-size: 11px;
  width: 99%;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__finance-message strong {
  white-space: nowrap;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__upgrade-plus-finance-message {
  font-size: 11px;
  line-height: 14px;
  margin: 8px auto 4px;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__learn-more-link {
  font-weight: 600;
  font-size: 11px;
  padding: 0;
  height: 15px;
  cursor: pointer;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__learn-more-upgrade-plus-link {
  line-height: 14px;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__financeModalContainer .c-modal-grid.col-xs-8 {
  min-width: 643px;
  max-width: 643px;
  padding-top: 30px !important;
  padding-right: 30px !important;
  padding-bottom: 30px !important;
  padding-left: 30px !important;
}
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__financeModalContainer .c-modal-grid,
.pricing-lib-price-19-2513-1 .total-cost-clarity-content__financeModalContainer .c-modal-small-view {
  background-color: #e0e6ef;
}
.pricing-lib-price-19-2513-1 .priceView-trade-in-messaging {
  margin-top: 12px;
  font-size: 13px;
  line-height: 18px;
}
.pricing-lib-price-19-2513-1 .priceView-trade-in-messaging span {
  color: #318000;
}
.pricing-lib-price-19-2513-1 .priceView-deferred-trade-in {
  margin: 25px auto;
}
.priceView-trade-in-messaging-modal-content .title {
  font-size: 25px;
}
.priceView-trade-in-messaging-modal-content .bottomPadding {
  padding-bottom: 41px;
}
.priceView-trade-in-messaging-modal-content .sub-header {
  font-size: 17px;
}
.priceView-trade-in-messaging-modal-content .body {
  font-size: 13px;
}
.priceView-trade-in-messaging-modal-content ol {
  padding-left: 14px;
}
.priceView-trade-in-messaging-modal-content ul {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 22px;
}
.priceView-trade-in-messaging-modal-content ol,
.priceView-trade-in-messaging-modal-content ul {
  line-height: 17px;
}
.priceView-trade-in-messaging-modal-content ol > li {
  margin-bottom: 12px;
}
.priceView-trade-in-messaging-modal-content ul > li {
  margin-bottom: 6px;
}
.priceView-trade-in-messaging-modal-content .unordered-list-container {
  background-color: #e0e6ef;
}
.priceView-trade-in-messaging-modal-content .close-btn-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.priceView-trade-in-messaging-modal-content .close-btn-container .close-btn {
  width: 222px;
}
.price-view-buy-now-pay-later-container {
  display: flex;
  align-items: center;
}
.price-view-buy-now-pay-later-container__divider {
  border-left: 1px solid #000;
  position: relative;
  padding: 13px 0px 13px 0px;
  display: inline-block;
  margin: 7px 16px 0 16px;
}
.price-view-buy-now-pay-later-container__divider--percent-margin {
  margin: 8%;
}
.price-view-buy-now-pay-later-container__divider span {
  background: #fff;
  padding: 0px 0;
  font-size: 9px;
  position: absolute;
  top: 7px;
  left: -7px;
}
.price-view-buy-now-pay-later-container .price-view-bnpl-payment-message {
  font-size: 11px;
  line-height: 16px;
  white-space: nowrap;
  margin-bottom: 0px;
}
.price-view-buy-now-pay-later-container .price-view-bnpl-payment-price {
  font-size: 25px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 0;
}
.price-view-buy-now-pay-later-container__learn-more-link {
  font-size: 11px;
}
.price-view-buy-now-pay-later-container__vendor {
  display: flex;
}
.price-view-buy-now-pay-later-container__vendor__text {
  line-height: 16px;
  margin-right: 5px;
}
.price-view-buy-now-pay-later-container__zip-image {
  background: url('../../../lib-price/lib/images/Zip_blk.png') center no-repeat;
  background-size: contain;
  width: 44px;
  height: 16px;
}
.price-view-buy-now-pay-later-container__sezzle-image {
  background: url('../../../lib-price/lib/images/Sezzle_Logo_FullColor.png') center no-repeat;
  background-size: contain;
  width: 72px;
  height: 18px;
}
.pricing-lib-price-19-2513-1 .annotations-content {
  font-weight: 400;
}
.pricing-lib-price-19-2513-1 .annotations-content__title {
  font-weight: 400;
  text-align: left;
  line-height: 14px;
  font-size: 13px;
  word-wrap: break-word;
  display: inline;
  padding-right: 2px;
  font-weight: 600!important;
  display: block;
  margin: 10px 0 0 0;
}
.pricing-lib-price-19-2513-1 .annotations-content__subhead {
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  display: block;
  margin: 0 0 10px 0;
}
.pricing-lib-price-19-2513-1 .priceView-down-payment {
  margin-top: 15px;
  font-size: 11px;
}
.pricing-lib-price-19-2513-1 .activated-pricing {
  font-size: 15px;
  margin-bottom: 15px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__header {
  font-size: 13px;
  margin-bottom: 8px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__options {
  padding: 0;
  margin: 0;
}
.pricing-lib-price-19-2513-1 .activated-pricing__options:before,
.pricing-lib-price-19-2513-1 .activated-pricing__options:after {
  content: ' ';
  display: table;
}
.pricing-lib-price-19-2513-1 .activated-pricing__options:after {
  clear: both;
}
.pricing-lib-price-19-2513-1 .activated-pricing__options:before,
.pricing-lib-price-19-2513-1 .activated-pricing__options:after {
  content: ' ';
  display: table;
}
.pricing-lib-price-19-2513-1 .activated-pricing__options:after {
  clear: both;
}
.pricing-lib-price-19-2513-1 .activated-pricing__option {
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 4% !important;
}
.pricing-lib-price-19-2513-1 .activated-pricing__option-price {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: block;
}
.pricing-lib-price-19-2513-1 .activated-pricing__description {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__button {
  width: 100%;
  border: solid 1px #c5cbd5;
  border-radius: 3px;
  background: none;
  text-align: left;
  position: relative;
  padding: 10px;
  color: #1d252c;
  /* TODO - better selector ? */
  /* mixin of some sort? */
}
.pricing-lib-price-19-2513-1 .activated-pricing__button > div:first-child {
  position: absolute;
  top: 6px;
  padding-right: 12px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__button:hover {
  border: solid 1px #0046be;
  padding: 10px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .activated-pricing__button:hover > div:first-child {
  top: 5px;
  padding-right: 11px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__button--selected,
.pricing-lib-price-19-2513-1 .activated-pricing__button--selected:hover {
  border: solid 2px #1d252c;
  padding: 9px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .activated-pricing__button--selected > div:first-child,
.pricing-lib-price-19-2513-1 .activated-pricing__button--selected:hover > div:first-child {
  top: 5px;
  padding-right: 11px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__button--disabled,
.pricing-lib-price-19-2513-1 .activated-pricing__button--disabled:hover {
  border: solid 1px #c5cbd5;
  padding: 10px;
  background: #e0e6ef;
  color: #55555a;
}
.pricing-lib-price-19-2513-1 .activated-pricing__button--disabled > div:first-child,
.pricing-lib-price-19-2513-1 .activated-pricing__button--disabled:hover > div:first-child {
  top: 6px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__arrow {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  width: 0;
  border: solid 5px transparent;
  border-bottom: solid 7px #1d252c;
}
.pricing-lib-price-19-2513-1 .activated-pricing__disclaimer {
  min-height: 32px;
  font-size: 11px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__see-details-section {
  font-size: 11px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__modal-label {
  margin: 5px 0px 7px 0px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__modal-label .c-info-i {
  display: inherit;
  margin-left: 5px;
  font-size: 15px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__modal-label .c-info-i-circle {
  position: inherit;
  margin-top: 3px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__modal-label .c-info-i-icon {
  position: relative;
  margin-left: -11px;
  top: -3.2px;
}
.pricing-lib-price-19-2513-1 .activated-pricing__modal-label .ficon-info {
  vertical-align: top;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal {
  color: #1d252c;
  line-height: 1.3;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal__carrier-logo {
  text-align: center;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal__unlocked-heading {
  margin: 25px 0px -10px 0px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1d252c;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal__heading {
  font-weight: 600;
  font-size: 15px !important;
  line-height: 18px;
  color: #1d252c;
  margin: 25px 0px 0px 0px !important;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal__content {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1d252c;
  margin: 0 0 3px !important;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal__single_list {
  margin: 0px;
  padding: 0px;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal__multiple_list {
  margin: 0px;
  padding-left: 15px;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal__no-list-item {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #1d252c;
  display: inline-block;
  margin: 0 0 3px !important;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal__no-list-item > span {
  margin-left: 0px;
}
.pricing-lib-price-19-2513-1 .pricing-option-modal hr {
  margin: 15px 0px -10px 0px;
}
.suggested-finance-options {
  display: flex;
  position: relative;
}
.suggested-finance-options .c-modal-grid,
.suggested-finance-options .c-modal-small-view {
  background-color: #e0e6ef;
}
.suggested-finance-options .content-divider {
  border-left: 1px solid #000;
  position: relative;
  padding: 13px 0;
  display: inline-block;
  margin: 7px 10px 0 10px;
  height: 26px;
}
.suggested-finance-options .content-divider span {
  background: #fff;
  padding: 0px 0;
  font-size: 9px;
  position: absolute;
  top: 7px;
  left: -7px;
}
.suggested-finance-options .monthly-payment-details {
  padding-bottom: 5px;
}
.suggested-finance-options .payment-details {
  font-size: 25px;
  margin-top: 5px;
  font-weight: 500;
}
.suggested-finance-options .static-details {
  font-size: 15px;
  font-weight: 500;
}
.suggested-finance-options .static-message {
  font-size: 11px;
}
.suggested-finance-options .finance-message {
  font-size: 11px;
  font-weight: 500;
  padding-bottom: 5px;
}
.suggested-finance-options .finance-modal-link {
  font-size: 11px;
  font-weight: 500;
  color: #0457c8;
}
.pricing-finance-options .finance-modal__container {
  padding: 25px;
}
.priceView__shimmer-price-skeleton {
  display: block;
}
.priceView__shimmer-price-skeleton--large {
  max-width: 200px;
  height: 80px;
}
.priceView__shimmer-price-skeleton--medium {
  max-width: 200px;
  height: 75px;
}
.priceView__shimmer-price-skeleton--small {
  max-width: 160px;
  height: 55px;
}
.priceView__shimmer-price-skeleton--mini {
  max-width: 130px;
  height: 35px;
}
.priceView__shimmer-price-skeleton--single {
  max-width: 75px;
  height: 18px;
}
.priceView__shimmer-price-skeleton--warranty {
  max-width: 75px;
  height: 35px;
  margin-left: auto;
}
.priceView__shimmer-price-skeleton--addOn {
  max-width: 75px;
  height: 35px;
}
.priceView__shimmer-price-skeleton--addOn-right {
  max-width: 75px;
  height: 35px;
  margin-left: auto;
}
.priceView-offer-badge {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.26px;
  font-weight: 400;
  color: #1d252c;
}
.priceView-offer-badge strong {
  font-weight: 700;
}
.priceView-offer-badge__bold-text {
  font-weight: 500;
}
.priceView-offer-badge__text,
.priceView-offer-badge__paid-member-icon {
  display: inline-block;
  vertical-align: middle;
}
.priceView-offer-badge__paid-member-icon {
  padding-right: 2px;
}
.priceView-offer-badge .totalTechEarlyAccessTagImg {
  width: 60px;
  margin-right: 2px;
}
.priceView-offer-badge a:hover {
  text-decoration: none;
}
.prvw-price-change-message__button {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  background-color: #f1f8ff;
  color: #2b5df5;
}
.prvw-price-change-message__button--layout-large {
  margin-top: 12px;
}
.prvw-price-change-message__button--layout-medium {
  margin-top: 16px;
}
.prvw-price-change-message__button--layout-small {
  margin-top: 8px;
}
.prvw-price-change-message__button-icon {
  display: inline-block;
  height: 15px;
  min-width: 15px;
  margin-right: 2px;
  fill: #2b5df5;
}
.prvw-price-change-message__button-icon svg {
  height: 100%;
}
.prvw-price-change-message__button-text {
  font-weight: 500;
  text-align: left;
}
.prvw-price-change-message__overlay {
  padding: 16px;
  width: 360px;
  transform: translateY(24px) translateX(8px);
}
.prvw-price-change-message__overlay-header {
  font-size: 18px;
  margin-bottom: 8px;
}
.black-friday-guaranteed-price__text {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #1d252c;
}
.black-friday-guaranteed-price__button {
  text-align: left;
  font-weight: 600;
}
.black-friday-guaranteed-price__button span {
  color: #2b5df5;
}
.black-friday-guaranteed-price-msg {
  font-family: "Human BBY Digital", "Human BBY Web", Arial, Helvetica, sans-serif;
}
.black-friday-guaranteed-price-msg__heading {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;
  color: #040c13;
}
.black-friday-guaranteed-price-msg__heading--small {
  font-size: 17px;
  line-height: 20px;
}
.black-friday-guaranteed-price-msg__body {
  font-size: 13px;
  line-height: 15px;
  color: #040c13;
}
.black-friday-guaranteed-price-msg__body-text {
  color: #1d252c;
}
.black-friday-guaranteed-price-msg__body-lead {
  margin-top: 16px;
  margin-bottom: 16px;
}
.black-friday-guaranteed-price-msg__body-divider {
  width: 100%;
  border: 1px solid #e0e6ef;
  margin-top: 16px;
  margin-bottom: 16px;
}
.black-friday-guaranteed-price-msg__body-divider--short {
  width: 56px;
  border: 1px solid #000000;
  margin-bottom: 16px;
}
.black-friday-guaranteed-price-msg__body-shop-link {
  color: #040c13;
}
.black-friday-guaranteed-price-msg__body-shop-link a {
  font-weight: 600;
}
.black-friday-guaranteed-price-msg__body-shop-link--small {
  font-size: 15px;
  line-height: 18px;
}
.black-friday-guaranteed-price-msg__disclaimer {
  font-size: 11px;
  line-height: 13px;
}
.black-friday-guaranteed-price-msg__disclaimer-title {
  font-weight: bold;
  margin-bottom: 0;
  font-size: inherit;
  margin-bottom: 5px;
  color: #55555a;
}
.black-friday-guaranteed-price-msg__disclaimer--small {
  margin-top: 34px;
}
.black-friday-deal-message-badge__text {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #1d252c;
}
.lowest-price-of-the-season-badge {
  background-color: #f1f8ff;
  border-radius: 4px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
}
.lowest-price-of-the-season-badge__btn {
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  color: #2b5df5;
}
.lowest-price-of-the-season-badge__btn-icon {
  height: 14px;
  width: 15px;
  fill: #0046be;
  margin-right: 4px;
}
.lowest-price-of-the-season-badge__btn--large {
  font-size: 13px;
  line-height: 15px;
}
.lowest-price-of-the-season-badge__btn--medium {
  font-size: 11px;
  line-height: 13px;
}
.lowest-price-of-the-season-badge__btn--small {
  font-size: 11px;
  line-height: 13px;
}
.holiday-price-modal-content__title {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 16px;
  color: #040c13;
}
.holiday-price-modal-content__link {
  color: #0457c8;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 16px;
}
.holiday-price-modal-content__disclaimer {
  color: #040c13;
  font-size: 15px;
  font-weight: 400;
}
.total-tech-price {
  margin-top: 12px;
  margin-bottom: 16px;
}
.total-tech-price--large {
  margin-top: 8px;
  margin-bottom: 16px;
}
.total-tech-price__button {
  font: 13px/15px 'Human BBY Digital', 'Human BBY Web', sans-serif;
  color: #0046be;
  font-weight: 600;
}
.total-tech-price__text {
  font-weight: 600;
  color: #1d252c;
}
.total-tech-price-savings__text {
  font: 13px/15px 'Human BBY Digital', 'Human BBY Web', sans-serif;
  color: #1d252c;
}
.total-tech-warranty {
  background: #fff;
  border: 1px solid #318000;
  border-radius: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 12px 12px 13px 12px;
  height: 60px;
  width: 100%;
}
.total-tech-warranty__text-full-term-savings {
  font-weight: 500;
}
.total-tech-warranty--large {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 12px 10px 13px 10px;
}
.total-tech-warranty__icon-pm-savings {
  width: 23.5px;
  height: 21.1px;
  margin-right: 10px;
}
.total-tech-warranty__icon-pm-savings--large {
  width: 21.5px;
}
.total-tech-warranty__icon-with-savings-value-large {
  width: 29.33px;
  height: 29.33px;
}
.total-tech-warranty__icon-pm-savings--green {
  fill: #318000;
}
.total-tech-warranty__text {
  font-family: 'Human BBY Digital', sans-serif;
  font-size: 11px;
  line-height: 14px;
  width: 80%;
  color: #040c13;
  height: 100%;
}
.total-tech-warranty__text-content {
  margin-bottom: 0;
}
.total-tech-warranty__icon-caret {
  height: 20px;
  color: #040c13;
  margin-left: 5px;
}
.disableTotalTechWarrantySavings {
  display: none;
}
.upgrade-plus {
  width: 100%;
  border: 1px solid #90959E;
  border-radius: 4px;
  padding: 16px;
}
.upgrade-plus.selected {
  border: 2px solid black;
}
.upgrade-plus .one-time-payment {
  padding-top: 6px;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #1D252C;
}
.upgrade-plus .priceView-tap-to-view-price button.priceView-tap-to-view-price {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #040C13;
}
.upgrade-plus .priceView-tap-to-view-price button.priceView-tap-to-view-price.priceView-tap-to-view-price-bold {
  color: #0457C8;
  font-size: 13px;
  line-height: 16px;
  min-width: 52px;
}
.upgrade-plus button.priceView-tap-to-view-price.priceView-tap-to-view-price-bold {
  font-size: 11px;
  line-height: 14px;
}
.upgrade-plus .pricing-price.pricing-lib-price-4-6-7.priceView-price {
  display: flex;
  flex: 1;
  flex-direction: row;
}
.upgrade-plus .priceView-tap-to-view-price {
  flex: 1;
}
.upgrade-plus .upgrade-plus-price {
  display: flex;
}
.upgrade-plus .upgrade-plus-price .priceView-hero-price.priceView-customer-price {
  line-height: 18px;
  color: #1D252C;
  font-weight: 500;
  font-size: 15px;
}
.upgrade-plus .upgrade-plus-price .pricing-price__savings-regular-price .pricing-price__savings {
  color: #E81E25;
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
  background-color: transparent;
}
.upgrade-plus .upgrade-plus-price .pricing-price__savings-regular-price .pricing-price__regular-price {
  font-size: 11px;
  line-height: 14px;
  color: #000000;
}
.upgrade-plus .upgrade-plus-price .pricing-price__regular-price-content {
  margin-top: 0;
  display: block;
}
.upgrade-plus .upgrade-plus-price .price-sale-message {
  display: inline-flex;
  flex: 1;
  flex-direction: column;
}
.upgrade-plus .upgrade-plus-price .price-sale-message .pricing-price__sale-message {
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
}
.upgrade-plus .upgrade-plus-price .one-time-payment-savings-customer-price {
  text-align: right;
}
.upgrade-plus .upgrade-plus-price .priceView-offer-badge__text {
  font-size: 9px;
  line-height: 11px;
}
.upgrade-plus .upgrade-plus-price .save-bundle-price {
  text-align: right;
}
.upgrade-plus .upgrade-plus-price .save-bundle-price .priceView-bundle-and-save__link .priceView-bundle-and-save__priceBlock-banner {
  font-size: 11px;
  line-height: 14px;
}
.upgrade-plus .upgrade-plus-price .save-bundle-price .priceView-bundle-and-save__link .priceView-bundle-and-save__priceBlock-banner span {
  display: block;
  text-align: right;
}
.upgrade-plus .upgrade-plus-price .pricing-price.pricing-lib-price-4-6-7.priceView-price {
  width: 100%;
  flex: 3;
  flex-direction: row;
}
.payment-options-title {
  font-weight: 500;
  font-family: 'Human BBY Digital';
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: #000000;
  padding-bottom: 8px;
}
.payment-option {
  align-items: flex-start;
  padding: 0;
  text-align: start;
  display: block;
  height: auto;
  padding-bottom: 8px;
  width: 100%;
}
.payment-qualification {
  font-family: 'Human BBY Digital';
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  padding-top: 14px;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}
.payment-qualification hr {
  display: inline-block;
  width: 100%;
  margin: 0 0 2px 5px;
  border-top: 1px solid #90959e;
}
.citi-payment-option {
  width: 100%;
  border: 1px solid #90959E;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  justify-content: space-between;
}
.citi-payment-option.selected {
  border: 2px solid black;
}
.citi-payment-option__img-card {
  width: 63px;
  height: 40px;
}
.citizens-payment-option {
  width: 100%;
  border: 1px solid #90959e;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  text-align: left;
  font-size: 11px;
}
.citizens-payment-option.selected {
  border: 2px solid black;
}
.citizens-payment-option__logo {
  width: 80px;
  float: right;
}
.citizens-payment-option__logo img {
  width: 100%;
}
.citizens-payment-option__before-price {
  font-weight: 500;
}
.citizens-payment-option__price {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
}
.citizens-payment-option__disclaimer {
  color: #70757d;
  margin-bottom: 8px;
}
.citizens-payment-option__learn-more-link a {
  font-weight: 500;
  color: #0046be;
}
.upgradePlus-citizens-payment-modal-container {
  cursor: default;
}
.upgradePlus-citizens-payment-modal {
  width: 560px;
  margin: 50px;
  font-family: 'Human BBY Digital';
  font-size: 15px;
}
.upgradePlus-citizens-payment-modal__progress-bar-and-labels {
  margin-bottom: 30px;
}
.upgradePlus-citizens-payment-modal__progress-bar {
  margin-bottom: 20px;
}
.upgradePlus-citizens-payment-modal__progress-label {
  display: flex;
  margin-bottom: 16px;
}
.upgradePlus-citizens-payment-modal__progress-label-title {
  font-weight: 500;
  margin-bottom: 6px;
}
.upgradePlus-citizens-payment-modal__progress-label-text {
  margin-bottom: 6px;
}
.upgradePlus-citizens-payment-modal__progress-chip {
  flex: 35px 0 0;
}
.upgradePlus-citizens-payment-modal__item-details {
  font-size: 13px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: flex-start;
}
.upgradePlus-citizens-payment-modal__item-image {
  width: 50px;
  flex-shrink: 0;
}
.upgradePlus-citizens-payment-modal__item-image img {
  width: 100%;
}
.upgradePlus-citizens-payment-modal__item-title {
  flex-grow: 1;
  margin: 0 12px 0 8px;
}
.upgradePlus-citizens-payment-modal__boxed {
  border: 1px solid #c5cbd5;
  padding: 16px;
}
.upgradePlus-citizens-payment-modal__item-price {
  font-size: 11px;
  flex: 0 0 50%;
}
.upgradePlus-citizens-payment-modal__item-price span {
  padding-left: 12px;
}
.upgradePlus-citizens-payment-modal__item-price .emphasis {
  font-size: 13px;
  font-weight: 500;
}
.upgradePlus-citizens-payment-modal__item-price > div {
  margin-bottom: 8px;
}
.upgradePlus-citizens-payment-modal__item-price > div:last-child {
  margin-bottom: 0;
}
.upgradePlus-citizens-payment-modal__item-price hr {
  margin: 8px 0;
}
.upgradePlus-citizens-payment-modal__item-price .two-col {
  display: flex;
  width: '100%';
}
.upgradePlus-citizens-payment-modal__item-price .two-col div:first-child {
  max-width: 50%;
}
.upgradePlus-citizens-payment-modal__item-price .column-2 {
  flex-grow: 1;
  text-align: right;
}
.upgradePlus-citizens-payment-modal__item-disclaimer {
  font-size: 9px;
  color: #70757d;
}
.upgradePlus-citizens-payment-modal__section {
  margin-bottom: 28px;
}
.upgradePlus-citizens-payment-modal__section div {
  font-size: 15px;
  margin-bottom: 8px;
}
.upgradePlus-citizens-payment-modal .upgradePlus-citizens-payment-modal__option {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #c5cbd5;
}
.upgradePlus-citizens-payment-modal .upgradePlus-citizens-payment-modal__option:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.upgradePlus-citizens-payment-modal .upgradePlus-citizens-payment-modal__option div:last-child {
  margin-bottom: 0;
}
.upgradePlus-citizens-payment-modal__faq-link {
  font-weight: bold;
  margin-bottom: 12px;
}
.upgradePlus-citizens-payment-modal__flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
}
.upgradePlus-citizens-payment-modal__section-title {
  font-size: 24px;
  font-weight: 500;
  margin: 24px auto 12px;
}
.upgradePlus-citizens-payment-modal__disclaimer {
  font-size: 11px;
  color: #70757d;
  margin-bottom: 8px;
}
.upgradePlus-citizens-payment-modal__disclaimer .emphasis {
  font-weight: 500;
}
.upgradePlus-citizens-payment-modal__disclaimer .emphasis2 {
  font-weight: 500;
  text-decoration: underline;
}
.upgradePlus-citizens-payment-modal__inline-link {
  font-weight: 500;
}
.upgradePlus-citizens-payment-modal__small {
  margin: 0;
  width: 100%;
  padding: 20px;
  font-size: 13px;
}
.upgradePlus-citizens-payment-modal__small .upgradePlus-citizens-payment-modal__section-title {
  font-size: 15px;
  margin: 20px auto 12px;
}
.upgradePlus-citizens-payment-modal__small .upgradePlus-citizens-payment-modal__item-details {
  display: block;
  font-size: 11px;
}
.upgradePlus-citizens-payment-modal__small .upgradePlus-citizens-payment-modal__item-image-and-name {
  padding-bottom: 8px;
  border-bottom: 1px solid #c5cbd5;
  margin-bottom: 8px;
}
.upgradePlus-citizens-payment-modal__small .upgradePlus-citizens-payment-modal__item-image {
  width: 50px;
  flex-shrink: 0;
}
.upgradePlus-citizens-payment-modal__small .upgradePlus-citizens-payment-modal__item-image img {
  width: 100%;
}
.upgradePlus-citizens-payment-modal__small .upgradePlus-citizens-payment-modal__item-title {
  flex-grow: 1;
  max-width: fit-content;
  margin: 0;
  margin-left: 20px;
}
.payment-from-text {
  font-family: 'Human BBY Digital';
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  /* identical to box height */
  /* Grayscale/Tech Black */
  color: #1D252C;
}
.installment-text {
  font-family: 'Human BBY Digital';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* Grayscale/Tech Black */
  color: #1D252C;
}
.installment-range-text {
  font-family: 'Human BBY Digital';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */
  /* Grayscale/Tech Black */
  color: #1D252C;
}
.original-price-text {
  font-family: 'Human BBY Digital';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding-top: 8px;
  /* Grayscale/Pure Black */
  color: #000000;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview {
  margin-top: 18px;
  font-size: 13px;
  line-height: 18px;
}
.pricing-lib-price-19-2513-1 .greentext {
  font-size: 14px;
}
.pricing-lib-price-19-2513-1 .priceView-ttSavings {
  padding-top: 14px;
}
.pricing-lib-price-19-2513-1 .priceView-ttSavingsButton {
  background-color: white;
  border-color: #C5CBD5;
  padding-left: 4px;
  padding-right: 4px;
}
.pricing-lib-price-19-2513-1 .priceView-ttSaveText {
  white-space: nowrap;
  font-family: Human BBY Digital;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #E81E25;
}
.pricing-lib-price-19-2513-1 .priceView-ttWithText {
  color: #000000;
  font-family: Human BBY Digital;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
.pricing-lib-price-19-2513-1 .priceView-ttText {
  color: #000000;
  font-family: Human BBY Digital;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  padding-right: 5px;
}
.pricing-lib-price-19-2513-1 .priceView-carret {
  background-image: url('../../../lib-price/lib/images/caret-right.png');
  width: 7px;
  height: 14px;
  background-repeat: no-repeat;
}
.pricing-lib-price-19-2513-1 .priceView-sv-ttSaveText {
  white-space: nowrap;
  font-family: Human BBY Digital;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  color: #E81E25;
}
.pricing-lib-price-19-2513-1 .priceView-sv-ttWithText {
  color: #000000;
  font-family: Human BBY Digital;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
}
.pricing-lib-price-19-2513-1 .priceView-sv-ttText {
  color: #000000;
  font-family: Human BBY Digital;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  padding-right: 5px;
}
.pricing-lib-price-19-2513-1 .priceView-sv-carret {
  background-image: url('../../../lib-price/lib/images/caret-right-sv.png');
  width: 6px;
  height: 12px;
  background-repeat: no-repeat;
}
.pricing-lib-price-19-2513-1 .mainBox {
  margin-top: 15px;
  border: 1px solid #C5CBD5;
  border-radius: 2px;
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 8px;
}
.pricing-lib-price-19-2513-1 .logoBox {
  padding-right: 18px;
  display: flex;
  align-items: center;
}
.pricing-lib-price-19-2513-1 .logo {
  width: 70px;
  height: 20px;
}
.pricing-lib-price-19-2513-1 .separator {
  width: 2px;
  height: 24px;
  background-color: #C5CBD5;
}
.pricing-lib-price-19-2513-1 .savingsBox {
  padding-left: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pricing-lib-price-19-2513-1 .promoRed {
  color: #E81E25;
}
.bby-video-player .age-gate-container {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 275px;
  z-index: 20;
}
.bby-video-player .age-gate-container.sv {
  z-index: 101;
}
.bby-video-player .lv .age-gate-validation {
  width: 335px;
  padding: 30px;
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.25);
}
.bby-video-player .sv {
  flex-direction: column;
}
.bby-video-player .sv .age-gate-validation {
  margin-top: 25px;
  padding: 15px;
  max-width: 312px;
}
.bby-video-player .sv .ag-instructions {
  margin-bottom: 15px;
}
.bby-video-player .sv .ag-error-msg {
  bottom: -17px;
}
.bby-video-player .sv .age-gate-container:after {
  content: "";
  width: 100%;
  margin-top: 30px;
}
.bby-video-player .sv .svEnhancedLayout .age-gate-container:after {
  border-bottom: none;
}
.bby-video-player .age-gate-validation {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  z-index: 2;
}
.bby-video-player .ag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 90px;
  height: 90px;
  margin-bottom: 15px;
  background-color: #1d252c;
  border-radius: 90px;
  font-size: 35px;
}
.bby-video-player .ag-title {
  margin-bottom: 10px;
  font-size: 17px;
  color: #000;
}
.bby-video-player .underage-title {
  margin-bottom: 24px;
}
.bby-video-player .ag-instructions {
  margin-bottom: 15px;
  font-size: 13px;
  color: #000;
}
.bby-video-player .ag-form {
  display: flex;
  flex-direction: column;
}
.bby-video-player .ag-form .submit-button {
  width: 80px;
  border-radius: 0 4px 4px 0;
  border: 1px solid #0046be;
  background-color: #0046be;
  font-size: 17px;
  color: #fff;
}
.bby-video-player .ag-form .ag-input-container {
  display: flex;
  flex-direction: row;
}
.bby-video-player .ag-form .ag-input-container .ag-input {
  box-shadow: inset 1px 2px 0 0 #e0e6ef;
  padding: 13px 24px 13px 10px;
  border-right: none;
  border-radius: 4px 0 0 4px;
}
.bby-video-player .ag-form .ag-input-container .ag-input::placeholder {
  font-style: italic;
}
.bby-video-player .ag-form .ag-input-container .ag-input.ag-error {
  border-color: #bb0628;
}
.bby-video-player .ag-form .ag-back-button {
  width: 100%;
  padding: 15px 24px 15px 10px;
  border-radius: 4px;
  border: 1px solid #0046be;
  background-color: #0046be;
  font-size: 17px;
  color: #fff;
}
.bby-video-player .ag-form .ag-error-msg {
  margin-top: 8px;
  font-size: 11px;
  color: #bb0628;
}
.bby-video-player.fullscreen-fallback {
  background-color: #1d252c;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
}
.bby-video-player.fullscreen-fallback .video-player-wrapper {
  height: 100%;
  width: 100%;
  z-index: 200;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.bby-video-player.fullscreen-fallback .video-content {
  width: 100%;
  height: 100vh;
}
.bby-video-player:not(.fullscreen-fallback) .video-content {
  padding-bottom: calc(100% * 9 / 16);
}
.bby-video-player .video-content {
  position: relative;
  min-width: 299px;
  min-height: 168px;
}
.bby-video-player .captions-list {
  padding: 8px 0;
  position: absolute;
  width: 134px;
  color: #fff;
  background: #1d252c;
}
.bby-video-player .captions-list.lv {
  bottom: 50px;
  right: 70px;
}
.bby-video-player .captions-list.sv {
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index: 99;
}
.bby-video-player .captions-list ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
.bby-video-player .captions-list .caption-item-btn {
  height: 43px;
  width: 100%;
  padding: 8px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.bby-video-player .captions-list .caption-item-btn:hover {
  background: #717178;
}
.bby-video-player .captions-list .caption-item-btn .checkmark {
  width: 20px;
  margin-right: 5px;
  fill: #fff200;
}
.bby-video-player .controller-button {
  display: inline-block;
  width: auto;
  background-color: transparent;
  border-color: transparent;
  padding: 0 6px;
}
.bby-video-player .media-player-cta-button {
  width: 100%;
  position: absolute;
  top: 10px;
  text-align: center;
}
.bby-video-player .media-player-cta-button.lv {
  z-index: 100;
}
.bby-video-player .media-player-cta-button.sv .media-player-cta-button-base {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}
.bby-video-player video::-webkit-media-controls + .media-player-controller-wrapper {
  display: none !important;
}
.bby-video-player .hover-wrapper.fullscreen {
  position: absolute;
  z-index: 2147483648;
}
.bby-video-player .hover-wrapper.fullscreen .slider-grabber,
.bby-video-player .hover-wrapper.fullscreen .slider-bar,
.bby-video-player .hover-wrapper.fullscreen .videoToolTip {
  position: absolute;
}
.bby-video-player .hover-wrapper.fullscreen .slider,
.bby-video-player .hover-wrapper.fullscreen .slider-track,
.bby-video-player .hover-wrapper.fullscreen .videoToolTipContainer {
  position: relative;
}
.bby-video-player .hover-wrapper.fullscreen .controller-button,
.bby-video-player .hover-wrapper.fullscreen .controller-buttons-wrapper,
.bby-video-player .hover-wrapper.fullscreen .controller-buttons,
.bby-video-player .hover-wrapper.fullscreen .volume-controller,
.bby-video-player .hover-wrapper.fullscreen .videoToolTip,
.bby-video-player .hover-wrapper.fullscreen .videoToolTipContainer,
.bby-video-player .hover-wrapper.fullscreen .slider {
  z-index: auto;
}
.bby-video-player .hover-wrapper.fullscreen .time-slider,
.bby-video-player .hover-wrapper.fullscreen .volume-slider {
  z-index: 0;
}
.bby-video-player .hover-wrapper.fullscreen .right-widgets,
.bby-video-player .hover-wrapper.fullscreen .left-widgets,
.bby-video-player .hover-wrapper.fullscreen .slider-track,
.bby-video-player .hover-wrapper.fullscreen .progress-bar-2 {
  z-index: 1;
}
.bby-video-player .hover-wrapper.fullscreen .hover-bar {
  z-index: 2;
}
.bby-video-player .hover-wrapper.fullscreen .progress-bar {
  z-index: 3;
}
.bby-video-player .hover-wrapper.fullscreen .slider-grabber {
  z-index: 4;
}
.bby-video-player .hover-wrapper.fullscreen .play-pause-overlay-button.sv {
  position: absolute;
}
.bby-video-player .video-player-wrapper {
  position: relative;
}
.bby-video-player .hover-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
}
.bby-video-player .display-wrapper {
  opacity: 0;
  visibility: hidden;
  transition: opacity 750ms, visibility 750ms;
}
.bby-video-player .display-wrapper.show-wrapper {
  opacity: 1;
  visibility: visible;
}
.bby-video-player .controller-buttons.lv {
  height: 86px;
  width: 100%;
  bottom: 0;
  left: 0;
}
.bby-video-player .media-player-controller-wrapper .right-widgets .controller-buttons {
  display: flex;
  justify-content: flex-end;
}
.bby-video-player .media-player-controller-wrapper.lv {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  bottom: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.bby-video-player .media-player-controller-wrapper.lv .media-row {
  display: flex;
  flex-direction: row;
  height: 39px;
}
.bby-video-player .media-player-controller-wrapper.lv .row-2 {
  padding: 0 13px;
}
.bby-video-player .media-player-controller-wrapper.lv .left-widgets {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.bby-video-player .media-player-controller-wrapper.lv .volume-slider {
  width: 100px;
  margin-right: 20px;
}
.bby-video-player .media-player-controller-wrapper.lv .time-slider {
  padding: 0 32px;
  width: 100%;
  z-index: 0;
}
.bby-video-player .media-player-controller-wrapper.lv .controller-buttons-wrapper {
  height: 88px;
  width: 100%;
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: rgba(29, 37, 44, 0.8);
}
.bby-video-player .media-player-controller-wrapper.sv .left-widgets {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bby-video-player .media-player-controller-wrapper.sv {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.bby-video-player .media-player-controller-wrapper.sv .controller-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bby-video-player .media-player-controller-wrapper.sv .video-time-progress {
  width: 100%;
  padding: 0 16px;
}
.bby-video-player .media-player-controller-wrapper.sv .video-time-progress.show-full-screen-fallback {
  padding-bottom: 62px;
}
.bby-video-player .media-player-controller-wrapper.sv .controller-buttons-row {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
.bby-video-player .media-player-controller-wrapper.sv .controller-buttons-row .controller-button {
  padding: 0;
  border-width: 0;
}
.bby-video-player .media-player-controller-wrapper.sv .controller-buttons-row .controller-button + .controller-button {
  margin-left: 12px;
}
.bby-video-player .media-player-controller-wrapper.background-color {
  background-color: rgba(29, 37, 44, 0.8);
}
.bby-video-player .loading-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bby-video-player .video-player-wrapper {
  position: relative;
  height: 100%;
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper .play-pause-button {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper .play-pause-overlay-button.lv {
  position: absolute;
  top: 4px;
  left: 4px;
  margin: 0;
  padding: 0;
  width: calc(100% - 4px * 2);
  height: calc(100% - 4px * 2);
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper .show-all-controls .play-pause-overlay-button.lv {
  height: calc(100% - 86px - 4px * 2);
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper.lv .play-pause-overlay-button img {
  height: 84px;
  width: 84px;
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper.lv .play-pause-overlay-button img.video-has-started {
  opacity: 0.9;
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper.sv .play-pause-wrapper {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper.sv .play-pause-button-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper.sv .play-pause-overlay-button {
  align-self: center;
  z-index: 1;
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper.sv .play-pause-overlay-button img {
  height: 50px;
  width: 50px;
}
.bby-video-player .video-player-wrapper .media-player-controller-wrapper button {
  background-color: transparent;
  border-color: transparent;
}
.bby-video-player .slider-wrapper.sliderHide {
  opacity: 0;
  width: 0 !important;
  height: 0;
  margin: 0;
}
.bby-video-player .slider {
  width: 100%;
  height: 43px;
  position: relative;
  cursor: pointer;
  outline: none;
}
.bby-video-player .slider:focus {
  outline: 1px solid initial;
}
.bby-video-player .slider .slider-track {
  background-color: rgba(29, 37, 44, 0.6);
  height: 4px;
  width: 100%;
  position: relative;
  top: 50%;
  border-radius: 5px;
  transition: none;
  z-index: 1;
}
.bby-video-player .slider .slider-grabber {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff200;
  position: absolute;
  top: calc(50% - 6px);
  z-index: 4;
  margin-left: 8px;
}
.bby-video-player .slider .slider-bar {
  background-color: #fff200;
  height: 4px;
  position: absolute;
  top: 50%;
  transition: none;
  border-radius: 5px;
}
.bby-video-player .slider .progress-bar {
  z-index: 3;
}
.bby-video-player .slider .progress-bar-2 {
  transition: width 750ms;
  background-color: #c5cbd5;
  z-index: 1;
}
.bby-video-player .slider .hover-bar {
  background-color: #fff;
  z-index: 2;
}
.bby-video-player .videoToolTipContainer {
  position: relative;
}
.bby-video-player .videoToolTipContainer.dynamic {
  display: block;
}
.bby-video-player .videoToolTipContainer .videoToolTip {
  padding: 3px 5px 4px 6px;
  position: absolute;
  top: -30px;
  display: inline-block;
  opacity: 0;
  color: #fff;
  background: #1d252c;
  text-align: center;
  white-space: nowrap;
  pointer-events: none;
}
.bby-video-player .videoToolTipContainer .videoToolTip.visible {
  opacity: 1;
}
.bby-video-player .videoToolTipContainer .videoToolTip.vanished {
  opacity: 0;
}
.bby-video-player .primary-transcript {
  position: relative;
  margin: 25px auto 0;
  border: 1px solid #c5cbd5;
  padding: 30px;
}
.bby-video-player .primary-transcript-close {
  padding: 0;
  border: none;
  position: absolute;
  top: 7px;
  right: 5px;
  width: 15px;
  height: 15px;
  text-align: center;
}
.bby-video-player .primary-transcript-collapsed {
  margin: 0;
  padding: 0;
  border: none;
}
.bby-video-player .primary-transcript-collapsed #primary-transcript-heading,
.bby-video-player .primary-transcript-collapsed .primary-transcript-close {
  display: none;
}
.bby-video-player .video-detail {
  display: block;
  margin-top: 20px;
}
.bby-video-player .video-detail.align-left {
  text-align: left;
}
.bby-video-player .video-detail.align-center {
  text-align: center;
}
.bby-video-player .video-player-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}
.bby-video-player .video-player-wrapper video {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  -webkit-opacity: 0.999;
}
.bby-video-player .video-player {
  width: 100%;
  background-color: #000000;
  text-align: center;
}
.bby-video-player .video-timer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}
.bby-video-player .video-timer-wrapper .timer {
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: 13px;
  text-align: center;
}
.bby-video-player .video-timer-wrapper .divider {
  padding-right: 2px;
  padding-left: 2px;
}
.bby-video-player .volume-controller.lv {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .backBox {
  color: #0457c8;
  padding: 8px 17px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .backBoxInner {
  display: flex;
  align-items: center;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .infoBox {
  background-color: #f0f2f4;
  padding: 25px 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .infoBoxTitle {
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .infoBoxSmallText {
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .videoBox {
  margin-top: 25px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .learnMoreButton {
  margin-top: 20px;
  border-color: #ffffff;
  color: #0457c8;
  background-color: #fff;
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  border-radius: 4px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .pricingTable {
  border: 1px solid #c5cbd5;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .pricingTableHeader {
  display: flex;
  padding: 18px 0;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #0046be 0%, #2b5df5 100%);
  border-bottom: 1px solid #c5cbd5;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .pricingTableHeaderText {
  color: #ffffff;
  font-weight: 500;
  line-height: 13px;
  padding-left: 9px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .pricingTableHeaderImg {
  height: 13px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .pricingTableInfo {
  display: flex;
  padding: 10px 18px;
  justify-content: space-between;
  background: #ffffff;
  align-items: center;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .pricingTableInfoPrices {
  display: flex;
  flex-direction: column;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .promoRed {
  color: #e81e25;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .memberSavingsBox {
  border-bottom: 1px solid #c5cbd5;
  padding: 25px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #318000;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .memberSavingsBoxPromoRed {
  border-bottom: 1px solid #c5cbd5;
  padding: 25px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e81e25;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .memberSavingsBoxTitle {
  font-size: 15px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .memberSavingsBoxSavings {
  font-size: 20px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .productInfoBox {
  padding: 28px 14px 0 14px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .totalTechLogo {
  width: 117px;
  margin-right: 19px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .atc-button-container {
  padding: 25px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .add-to-cart-button {
  width: 100%;
}
.size-s .pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal {
  z-index: 99;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .priceView-total-tech-preview-slider-overlay {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .priceView-total-tech-preview-slider {
  background: #fff;
  position: absolute;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  padding: 0;
  overflow: auto;
  transition: right 0.5s;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .priceView-total-tech-preview-slider .container,
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .priceView-total-tech-preview-slider .priceView-total-tech-preview-slider-content {
  height: 100%;
  padding: 0;
  margin: 0;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .priceView-total-tech-preview-slider {
  padding-top: 9px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .priceView-total-tech-preview-slider .lv {
  display: block !important;
  min-width: 0px !important;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .largeView {
  max-width: 360px;
  min-width: 360px;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal.open .priceView-total-tech-preview-slider-overlay {
  opacity: 1;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal.open .priceView-total-tech-preview-slider {
  right: 0;
}
.pricing-lib-price-19-2513-1 .priceView-total-tech-preview-modal .close-button {
  background: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  right: initial;
  left: -36px;
  top: 15px;
  padding: 8px;
  border: 0;
  border-radius: 4px 0 0 4px;
  font-size: 38px;
  font-weight: 300;
  line-height: 0.5;
}
.pricing-lib-price-19-2513-1 #price-view-total-tech-membership-slider .priceView-total-tech-preview-slider .lv {
  display: block;
  min-width: min-content;
}
.upgrade-plus-slidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  cursor: default;
}
.upgrade-plus-slidebar .backBox {
  color: #0457c8;
  padding: 17px;
  cursor: pointer;
}
.upgrade-plus-slidebar .backBoxInner {
  display: flex;
  align-items: center;
}
.upgrade-plus-slidebar .backBoxInner svg {
  position: absolute;
  height: 30px;
  margin-left: -4px;
}
.upgrade-plus-slidebar .slider-overlay {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s;
}
.upgrade-plus-slidebar .slider {
  background: #fff;
  position: absolute;
  top: 0;
  right: -100%;
  height: 100%;
  padding: 0;
  transition: right 0.5s;
  padding-top: 9px;
}
.upgrade-plus-slidebar.openPlusSidebar .slider-overlay {
  opacity: 1;
}
.upgrade-plus-slidebar.openPlusSidebar .slider {
  right: 0;
}
.pricing-lib-price-19-2513-1 .trade-in-message-price {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  height: 20px;
  align-items: center;
}
.pricing-lib-price-19-2513-1 .trade-in-message-price .trade-in-message-price-overlay {
  background: #effaf3;
  border-radius: 4px;
  padding: 4px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  height: 20px;
  align-items: center;
}
.pricing-lib-price-19-2513-1 .trade-in-message-price .trade-in-message-price-inner {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #318000;
  align-items: center;
}
.pricing-lib-price-19-2513-1 .due-today {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}
.pricing-lib-price-19-2513-1 .upgrade-plus .due-today {
  font-size: 11px;
  line-height: 14px;
}
.pricing-lib-price-19-2513-1 .c-overlay-override {
  padding: 0;
  width: 380px;
  height: 146px;
  box-shadow: 0 1px 4px 0 #00000026;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay {
  font-family: 'Human BBY Digital';
  line-height: 14px;
  padding: 20px 20px 28px;
  font-size: 11px;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__heading {
  font-weight: 500;
  color: #1d252c;
  margin-bottom: 8px;
  font-size: inherit;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__list {
  font-weight: 400;
  color: #040c13;
  padding: 0 0 0 20px;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__list li {
  margin-bottom: 14px;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__list li:nth-last-child {
  margin-bottom: 0;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon {
  width: 14px;
  align-self: center;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon:hover .c-info-i-circle {
  fill: #fff;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon:focus .c-info-i-circle {
  fill: #fff;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon:hover .c-info-i-text {
  fill: #318000;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon:focus .c-info-i-text {
  fill: #318000;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon .c-info-i-circle {
  stroke: #318000;
  fill: none;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon .c-info-i-text {
  fill: #318000;
}
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon .c-info-i-circle,
.pricing-lib-price-19-2513-1 .trade-in-info-overlay__icon .c-info-i-text {
  stroke-width: 2px;
}
