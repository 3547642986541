.priceView__shimmer-price-skeleton {
    display: block;
    &--large {
        max-width: 200px;
        height: 80px;
    }
    &--medium {
        max-width: 200px;
        height: 75px;
    }
    &--small {
        max-width: 160px;
        height: 55px;
    }
    &--mini {
        max-width: 130px;
        height: 35px;
    }
    &--single {
        max-width: 75px;
        height: 18px;
    }
    &--warranty {
        max-width: 75px;
        height: 35px;
        margin-left: auto;
    }
    &--addOn {
        max-width: 75px;
        height: 35px;
    }
    &--addOn-right {
        max-width: 75px;
        height: 35px;
        margin-left: auto;
    }
}
