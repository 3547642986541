@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {

  .c-overlay-override {
    padding: 0;
    width: 380px;
    height: 146px;
    box-shadow: 0 1px 4px 0 #00000026;
  }

  .trade-in-info-overlay {
    font-family: 'Human BBY Digital';
    line-height: 14px;
    padding: 20px 20px 28px;
    font-size: 11px;

    &__heading {
      font-weight: 500;
      color: @tech-black;
      margin-bottom: 8px;
      font-size: inherit;
    }

    &__list {
      font-weight: 400;
      color: @black-text;
      padding: 0 0 0 20px;

      li {
        margin-bottom: 14px;

        &:nth-last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .trade-in-info-overlay__icon {
    width: 14px;
    align-self: center;

    &:hover .c-info-i-circle {
      fill: @pure-white;
    }

    &:focus .c-info-i-circle {
      fill: @pure-white;
    }

    &:hover .c-info-i-text {
      fill: @success-green;
    }

    &:focus .c-info-i-text {
      fill: @success-green;
    }

    .c-info-i-circle {
      stroke: @success-green;
      fill: none;
    }

    .c-info-i-text {
      fill: @success-green;
    }

    .c-info-i-circle,
    .c-info-i-text {
      stroke-width: 2px;
    }
  }
}