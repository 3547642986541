@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';
@import '~@bby/video-player/dist/index.less';
@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .priceView-total-tech-preview-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .backBox {
      color: #0457c8;
      padding: 8px 17px;
    }
    .backBoxInner {
      display: flex;
      align-items: center;
    }
    .infoBox {
      background-color: #f0f2f4;
      padding: 25px 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .infoBoxTitle {
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }
    .infoBoxSmallText {
      font-weight: 400;
      font-size: 11px;
      line-height: 14px;
    }
    .videoBox {
      margin-top: 25px;
    }
    .learnMoreButton {
      margin-top: 20px;
      border-color: #ffffff;
      color: #0457c8;
      background-color: #fff;
      display: flex;
      padding: 15px;
      align-items: center;
      justify-content: center;
      margin-bottom: 16px;
      border-radius: 4px;
    }
    .pricingTable {
      border: 1px solid #c5cbd5;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
    .pricingTableHeader {
      display: flex;
      padding: 18px 0;
      align-items: center;
      justify-content: center;
      background: linear-gradient(180deg; #0046be 0%; #2b5df5 100%);
      border-bottom: 1px solid #c5cbd5;
    }
    .pricingTableHeaderText {
      color: #ffffff;
      font-weight: 500;
      line-height: 13px;
      padding-left: 9px;
    }
    .pricingTableHeaderImg {
      height: 13px;
    }
    .pricingTableInfo {
      display: flex;
      padding: 10px 18px;
      justify-content: space-between;
      background: #ffffff;
      align-items: center;
    }
    .pricingTableInfoPrices {
      display: flex;
      flex-direction: column;
    }
    .promoRed {
      color: #e81e25;
    }
    .memberSavingsBox {
      border-bottom: 1px solid #c5cbd5;
      padding: 25px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #318000;
    }
    .memberSavingsBoxPromoRed {
      border-bottom: 1px solid #c5cbd5;
      padding: 25px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #e81e25;
    }
    .memberSavingsBoxTitle {
      font-size: 15px;
    }
    .memberSavingsBoxSavings {
      font-size: 20px;
    }
    .productInfoBox {
      padding: 28px 14px 0 14px;
    }
    .totalTechLogo {
      width: 117px;
      margin-right: 19px;
    }

    .atc-button-container {
      padding: 25px;
    }

    .add-to-cart-button {
      width: 100%;
    }

    .size-s & {
      z-index: 99;
    }

    .priceView-total-tech-preview-slider-overlay {
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.5s;
    }

    .priceView-total-tech-preview-slider {
      background: @pure-white;
      position: absolute;
      top: 0;
      right: -100%;
      height: 100%;
      width: 100%;
      padding: 0;
      overflow: auto;
      transition: right 0.5s;
      .container,
      .priceView-total-tech-preview-slider-content {
        height: 100%;
        padding: 0;
        margin: 0;
      }
    }

    .priceView-total-tech-preview-slider {
      padding-top: 9px;
      .lv {
        display: block !important;
        min-width: 0px !important;
      }
    }

    .largeView {
      max-width: 360px;
      min-width: 360px;
    }

    &.open {
      .priceView-total-tech-preview-slider-overlay {
        opacity: 1;
      }

      .priceView-total-tech-preview-slider {
        right: 0;
      }
    }

    .close-button {
      background: @pure-white;
      display: flex;
      justify-content: center;
      align-content: center;
      position: absolute;
      right: initial;
      left: -36px;
      top: 15px;
      padding: 8px;
      border: 0;
      border-radius: 4px 0 0 4px;
      font-size: 38px;
      font-weight: 300;
      line-height: 0.5;
    }
  }

  #price-view-total-tech-membership-slider
    .priceView-total-tech-preview-slider
    .lv {
    // Some CSS from the PLP view is affecting the video player because of .lv -- we need to override
    display: block;
    min-width: min-content;
  }
}
