@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";
@parent-selector: ~"@{package-namespace}";

@keyframes pricing-price-strikethrough {
  0% { width: 0; }
  100% { width: 100%; }
}

.@{parent-selector} {
  .priceView-previous-price {
    .font-weight-medium;

    .large({
      font-size: 25px;
      line-height: 29px;
    });
    .medium({
      font-size: 20px;
      line-height: 24px;
    });
    .small({
      font-size: 20px;
      line-height: 21px;
    });
    .mini({
      font-size: 15px;
      line-height: 18px;
      .font-weight-normal;
    });

    display: inline-block;
    position: relative;
    text-decoration: line-through;

    .priceView-subscription-units {
      font-size: 20px;
      line-height: 24px;
      .large({
        font-size: 25px;
        line-height: 24px;
      });
      .medium({
        font-size: 20px;
        line-height: 20px;
      });
      .small({
        font-size: 20px;
        line-height: 20px;
      });
      .mini({
        font-size: 15px;
        line-height: 18px;
      });
    }

    .priceView-total-tech-units {
      .font-weight-normal;

      font-size: 15px;
      line-height: 18px;
      .large({
        font-size: 15px;
        line-height: 18px;
      });
      .medium({
        font-size: 13px;
        line-height: 16px;
      });
      .small({
        font-size: 13px;
        line-height: 16px;
      });
      .mini({
        font-size: 11px;
        line-height: 14px;
      });
    }
  }

  &.priceView-dynamic-price .priceView-previous-price {
    text-decoration: none;

    &::after {
      content: ' ';
      position: absolute;
      top: 49%;
      left: 0;
      width: 100%;
      height: 2px;
      background: @tech-black;
      animation-name: pricing-price-strikethrough;
      animation-duration: 0.8s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
  }
}
