// @import (reference) '~sc-pattern-library/less/variables.less';
@import (reference) "../../package-namespace.less";
@import "../savings-regular-price/savings-regular-price.less";
@import "../savings-regular-price/regular-price-description-content.less";
@import "../member-pricing/member-offers.less";
@import "../member-pricing/member-pricing.less";
@import "../price-messaging/price-messaging.less";
@import "../../apps/buying-options-price/lowest-open-box-price/open-box.less";
@import "../../apps/buying-options-price/buy-new-price/buy-new-option.less";
@import "../restricted-pricing/restricted-price.less";
@import "../shared/disclaimer/price-disclaimer.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .price-view-pb {
    .font-weight-normal;

    font-size: 13px;
    line-height: 1;
    color: @tech-black;
  }

  .priceView-free {
    font-weight: bold;
    text-transform: uppercase;
  }

  .priceView-price-prefix {
    margin-bottom: 0px;
  }

  .priceView-hero-price {
    .font-weight-medium;

    font-size: 30px;
    line-height: 35px;
    color: @tech-black; // @TO DO Please share the weight again

    .large({
      font-size: 30px;
      line-height: 35px;
    });

    .medium({
      font-size: 25px;
      line-height: 29px;
    });

    .small({
      font-size: 25px;
      line-height: 29px;
    });

    .mini({
      font-size: 15px;
      line-height: 18px;
      vertical-align: middle;
    });

    .single({
      font-size: 13px;
      line-height: 16px;
    });
}

.single-price {
  .priceView-hero-price {
    font-weight: 400;
  }

  &.single-bold .priceView-hero-price {
    font-weight: 500;
  }
}

.priceView-total-tech-units {
  .font-weight-normal;

  font-size: 15px;
  line-height: 18px;
  color: @tech-black;

  .large({
    font-size: 15px;
    line-height: 18px;
  });

.medium({
  font-size: 13px;
  line-height: 16px;
});

.small({
  font-size: 13px;
  line-height: 16px;
});

.mini({
  font-size: 11px;
  line-height: 14px;
});
}

.priceView-price-match-banner {
  margin: 20px 0;
  color: @human-blue;

  .icon-Circle_Dollar {
    font-size: 23px;
    padding-right: 4px;
  }
}

.priceView-offer-wrapper {
  display: block;
  position: relative;
}

.priceView-down-payment-message {
  margin-top: 10px;
}

.priceView-carrier-bill-disclaimer {
  margin-top: 15px;
  font-size: 11px;
}

.priceview-expiration-timer {
  margin-top: 4px;
  margin-bottom: 8px;
}
}

.priceView-layout-small {
  .priceView-previous-price {
    font-size: 20px;
    line-height: 20px;
  }
}

.priceView-layout-mini {

  .priceView-mini-price-red,
  .priceView-restricted-price-red {

    .pricing-price__sale-message,
    .priceView-hero-price {
      color: @value-red;
    }
  }

  .priceView-hero-price {
    font-size: 15px;
    line-height: 19px;
  }

  .priceView-subscription-units {
    font-size: 15px;
    line-height: 19px;
  }
}