@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";
@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
    .mainBox {
        margin-top: 15px;
        border: 1px solid #C5CBD5;
        border-radius: 2px;
        padding: 12px 17px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-right: 8px;
    }
    .logoBox {
        padding-right: 18px;
        display: flex;
        align-items: center;
    }
    .logo {
        width: 70px;
        height: 20px;
    }
    .separator {
        width: 2px;
        height: 24px;
        background-color: #C5CBD5
    }
    .savingsBox {
        padding-left: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .promoRed {
        color: #E81E25;
    }
}