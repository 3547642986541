@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .priceView-price-prefix {
    margin-bottom: 0px;
  }

  .priceView-hero-price {
    .font-weight-medium;

    font-size: 30px;
    line-height: 35px;
    color: @tech-black;

    .large( {
        font-size: 30px;
        line-height: 35px;
      }

    );

    .medium( {
        font-size: 25px;
        line-height: 29px;
      }

    );

    .small( {
        font-size: 25px;
        line-height: 29px;
      }

    );

    .mini( {
        font-size: 15px;
        line-height: 18px;
        vertical-align: middle;
      }

    );

    .single( {
        font-size: 13px;
        line-height: 16px;
      }

    );
  }

  .priceView-customer-price {
    font-weight: 500;

    .large ( {
        font-size: 30px;
        line-height: 35px;
      }

    );
    ;

    .medium( {
        font-size: 25px;
        line-height: 29px;
      }

    );

    .small ( {
        font-size: 25px;
        line-height: 29px;
      }

    );
    ;

    .mini( {
        font-size: 15px;
        line-height: 18px;
      }

    );

    .priceView-subscription-units {
      font-size: 25px;
      line-height: 29px;

      .large ( {
          font-size: 25px;
          line-height: 29px;
        }

      );
      ;

      .medium ( {
          font-size: 20px;
          line-height: 24px;
        }

      );
      ;

      .small ( {
          font-size: 20px;
          line-height: 24px;
        }

      );
      ;

      .mini ( {
          font-size: 15px;
          line-height: 18px;
        }

      );
      ;
    }

    .priceView-total-tech-units {
      font-size: 15px;
      line-height: 18px;

      .large ( {
          font-size: 15px;
          line-height: 18px;
        }

      );
      ;

      .medium ( {
          font-size: 13px;
          line-height: 16px;
        }

      );
      ;

      .small ( {
          font-size: 13px;
          line-height: 16px;
        }

      );
      ;

      .mini ( {
          font-size: 11px;
          line-height: 14px;
        }

      );
      ;
    }

    .total-membership-monthly,
    .total-membership-yearly {
      letter-spacing: 0px;
      text-align: left;
      margin: 5px;
      font-family: Human BBY Digital;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;

      .mini {
        font-size: 11px;
        line-height: 14px;
      }

    }

    .total-membership-yearly {
      margin: 2px;
    }
  }

  .priceView-free {
    font-weight: 500;
    text-transform: uppercase;
  }

  .activated-previous-price {
    padding-left: 8px;

    .priceView-previous-price {
      font-size: 16px !important;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      span,
      span.priceView-subscription-units {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

  }

  .priceView-acquisition-preview{
    margin-top: 15px;
  }

}