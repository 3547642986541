@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .pv-warranty-price {
    font-family: @font-family-base;
    text-align: right;
    &__member-badge {
      font-size: 9px;
      line-height: 11px;
      white-space: nowrap;
    }
    &__customer-price {
      font-size: 13px;
      font-weight: 500;
    }
    &__regular-price {
      font-size: 13px;
      text-decoration: line-through;
    }
    &__monthly-price {
      font-size: 11px;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .pv-warranty-price--primary {
    .pv-warranty-price {
      &__monthly-price {
        font-size: 11px;
        white-space: nowrap;
        overflow: hidden;
      }
      &__customer-price {
        font-size: 15px;
        font-weight: normal;
      }
    }
  }

  .pv-member-badge__text,
  .pv-member-badge__icon {
    display: inline-block;
    vertical-align: middle;
  }

  .pv-member-badge__icon {
    padding-right: 2px;
  }

  .pv-member-badge__text--bold {
    font-weight: 500;
  }
}
