@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .priceView-my-bby-offer-price-block {
    display: block;
  }

  .priceView-my-bby-offer {
    display: inline-block;
    padding-left: 2px;
    margin-bottom: 5px;
    margin-top: 8px;

    .icon {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
