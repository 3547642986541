@import (reference) "~@bestbuy/brix-web/less/";
@import (reference) "../../package-namespace.less";

.prvw-price-change-message {

  &__button {
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 4px;
    background-color: @highlight-blue;
    color: @light-blue;

    &--layout-large {
      margin-top: 12px;
    }

    &--layout-medium {
      margin-top: 16px;
    }

    &--layout-small {
      margin-top: 8px;
    }
  }

  &__button-icon {
    display: inline-block;
    height: 15px;
    min-width: 15px;
    margin-right: 2px;
    fill: @light-blue;
    svg {
      height: 100%;
    }
  }

  &__button-text {
    font-weight: 500;
    text-align: left;
  }

  &__overlay {
    padding: 16px;
    width: 360px;
    transform: translateY(24px) translateX(8px);
  }

  &__overlay-header {
    font-size: 18px;
    margin-bottom: 8px;
  }
}


// // * * * * * * REMOVE BELOW * * * * * * //
// // it's a hack to test PLOverlay positioning relative to PriceBlock
// .component {
//   display: flex;
//   justify-content: flex-end;
// }
// // * * * * * * REMOVE ABOVE * * * * * * //