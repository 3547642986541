@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .pricing-option-modal {
    color: @tech-black;
    line-height: 1.3;

    &__carrier-logo {
      text-align: center;
    }

    &__unlocked-heading {
      margin: 25px 0px -10px 0px;
      .font-weight-normal;

      font-size: 13px;
      line-height: 16px;
      color: @tech-black;
    }

    &__heading {
      .font-weight-medium;

      font-size: 15px !important;
      line-height: 18px;
      color: @tech-black;  // @TO DO Please share the weight again
      margin: 25px 0px 0px 0px !important;
    }

    &__content {
      .font-weight-normal;

      font-size: 13px;
      line-height: 16px;
      color: @tech-black;  // @TO DO Please share the weight again
      margin: 0 0 3px !important;
    }

    &__single_list {
      margin: 0px;
      padding: 0px;
    }

    &__multiple_list {
      margin: 0px;
      padding-left: 15px;
    }

    &__no-list-item {
      .font-weight-normal;

      font-size: 13px;
      line-height: 16px;
      color: @tech-black;
      display: inline-block;
      margin: 0 0 3px !important;
    }

    &__no-list-item > span {
      margin-left: 0px;
    }

    hr {
      margin: 15px 0px -10px 0px;
    }
  }
}
