@import (reference) '~@bestbuy/brix-web/less/variables.less';

@grey-clock-icon-svg: "data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M1.75 7C1.75 4.1055 4.1055 1.75 7 1.75C9.89508 1.75 12.25 4.1055 12.25 7C12.25 9.89508 9.89508 12.25 7 12.25C4.1055 12.25 1.75 9.89508 1.75 7ZM0.875 7C0.875 10.3775 3.6225 13.125 7 13.125C10.3775 13.125 13.125 10.3775 13.125 7C13.125 3.6225 10.3775 0.875 7 0.875C3.6225 0.875 0.875 3.6225 0.875 7ZM9.02417 9.6425C9.10933 9.72825 9.22133 9.77083 9.33333 9.77083C9.44533 9.77083 9.55733 9.72825 9.6425 9.6425C9.81342 9.47158 9.81342 9.19508 9.6425 9.02417L7.4375 6.81858V3.0625C7.4375 2.821 7.2415 2.625 7 2.625C6.7585 2.625 6.5625 2.821 6.5625 3.0625V7C6.5625 7.11608 6.60858 7.2275 6.69083 7.30917L9.02417 9.6425Z' fill='%2355555A' /%3E%3C/svg%3E";
@red-clock-icon-svg: "data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M1.75 7C1.75 4.1055 4.1055 1.75 7 1.75C9.89508 1.75 12.25 4.1055 12.25 7C12.25 9.89508 9.89508 12.25 7 12.25C4.1055 12.25 1.75 9.89508 1.75 7ZM0.875 7C0.875 10.3775 3.6225 13.125 7 13.125C10.3775 13.125 13.125 10.3775 13.125 7C13.125 3.6225 10.3775 0.875 7 0.875C3.6225 0.875 0.875 3.6225 0.875 7ZM9.02417 9.6425C9.10933 9.72825 9.22133 9.77083 9.33333 9.77083C9.44533 9.77083 9.55733 9.72825 9.6425 9.6425C9.81342 9.47158 9.81342 9.19508 9.6425 9.02417L7.4375 6.81858V3.0625C7.4375 2.821 7.2415 2.625 7 2.625C6.7585 2.625 6.5625 2.821 6.5625 3.0625V7C6.5625 7.11608 6.60858 7.2275 6.69083 7.30917L9.02417 9.6425Z' fill='%23BB0628' /%3E%3C/svg%3E";
@promo-red-clock-icon-svg: "data:image/svg+xml, %3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M1.75 7C1.75 4.1055 4.1055 1.75 7 1.75C9.89508 1.75 12.25 4.1055 12.25 7C12.25 9.89508 9.89508 12.25 7 12.25C4.1055 12.25 1.75 9.89508 1.75 7ZM0.875 7C0.875 10.3775 3.6225 13.125 7 13.125C10.3775 13.125 13.125 10.3775 13.125 7C13.125 3.6225 10.3775 0.875 7 0.875C3.6225 0.875 0.875 3.6225 0.875 7ZM9.02417 9.6425C9.10933 9.72825 9.22133 9.77083 9.33333 9.77083C9.44533 9.77083 9.55733 9.72825 9.6425 9.6425C9.81342 9.47158 9.81342 9.19508 9.6425 9.02417L7.4375 6.81858V3.0625C7.4375 2.821 7.2415 2.625 7 2.625C6.7585 2.625 6.5625 2.821 6.5625 3.0625V7C6.5625 7.11608 6.60858 7.2275 6.69083 7.30917L9.02417 9.6425Z' fill='%23E81E25' /%3E%3C/svg%3E";
@value-promo-red: #E81E25;

.prvw-deal-expiration-timer {
  font-family: @font-family-base;
  font-weight: 400;

  .message {
    display: flex;
    align-items: center;
    color: @dark-gray;
    font-style: italic;
    font-size: 11px;
    letter-spacing: 0.2px;

    .deal-clock-icon {
      width: 14px;
      height: 14px;
      vertical-align: bottom;
      background: url(@grey-clock-icon-svg) no-repeat center;
      background-size: contain;
      display: inline-block;
      margin-right: 3px;
    }

    &--red {
      color: @value-red;

      .deal-clock-icon {
        background: url(@red-clock-icon-svg) no-repeat center;
      }

    }

    &--promo-red {
      color: @value-promo-red;

      .deal-clock-icon {
        background: url(@promo-red-clock-icon-svg) no-repeat center;
      }

    }
  }
}