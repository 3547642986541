@import (reference) '~@bestbuy/brix-web/less/';
@import (reference) '../../package-namespace.less';

.holiday-price-modal-content {
  &__title {
    font-weight: 500;
    font-size: 25px;
    margin-bottom: 16px;
    color: @black-text;
  }
  &__link {
    color: @link-blue;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  &__disclaimer {
    color: @black-text;
    font-size: 15px;
    font-weight: 400;
  }
}
