@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';
@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .XBox-terms-condition {
    color: @tech-black;
    .font-weight-normal;
    &.small-view {
      padding: 10px;
      .main-heading,
      .sub-title {
        text-align: left;
      }
      .modal-terms {
        display: block;
        margin-top: 30px;
        .modal-terms-container {
          width: 100%;
          margin: 0;
        }
      }
    }
    .main-heading {
      .font-weight-medium;
      font-size: 25px;
      line-height: 29px;
      text-align: center;
      margin-bottom: 20px;
    }
    .sub-title {
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 10px;
    }
    .sub-heading {
      .font-weight-medium;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      margin: 15px 0 20px;
    }
    .check-list-svg {
      width: 45px;
      height: 45px;
      margin-bottom: 10px;
      background: url('./images/checklist-icon.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
    }
    .cart-svg {
      width: 45px;
      height: 45px;
      margin-bottom: 10px;
    }
    .content {
      font-size: 13px;
      line-height: 16px;
    }
    .modal-terms {
      display: flex;
      margin-bottom: 30px;
      .modal-terms-container {
        width: 50%;
        margin: 10px 20px;
      }
    }
    .disclaimer {
      font-size: 13px;
      line-height: 16px;
      margin-top: 30px;
    }
  }
}
