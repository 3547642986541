.price-view-buy-now-pay-later-container {
  display: flex;
  align-items: center;

  &__divider {
    border-left: 1px solid #000;
    position: relative;
    padding: 13px 0px 13px 0px;
    display: inline-block;
    margin: 7px 16px 0 16px;
    // height: 100%;

    &--percent-margin {
      margin: 8%;
    }

    span {
      background: #fff;
      padding: 0px 0;
      font-size: 9px;
      position: absolute;
      top: 7px;
      left: -7px;
    }
  }
  .price-view-bnpl-payment-message {
    font-size: 11px;
    line-height: 16px;
    white-space: nowrap;
    margin-bottom: 0px;
  }
  .price-view-bnpl-payment-price {
    font-size: 25px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 0;
  }

  &__learn-more-link {
    font-size: 11px;
    // margin-top: 4px;
  }

  &__vendor {
    display: flex;

    &__text {
      line-height: 16px;
      margin-right: 5px;
    }
  }

  &__zip-image {
    background: url('../../images/Zip_blk.png') center no-repeat;
    background-size: contain;
    width: 44px;
    height: 16px;
  }

  &__sezzle-image {
    background: url('../../images/Sezzle_Logo_FullColor.png') center no-repeat;
    background-size: contain;
    width: 72px;
    height: 18px;
  }
}
