@import (reference) '~@bestbuy/brix-web/less/';

.citi-payment-option {
  width: 100%;
  border: 1px solid #90959e;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  justify-content: space-between;
  &.selected {
    border: 2px solid black;
  }

  &__img-card {
    width: 63px;
    height: 40px;
  }
}
