@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .price-alert-indicator-wrapper {
    padding: 10px;
    margin-left: 15px;
  }

  .price-alert-indicator-button {
    background-color: white;
    border: none;
    // Prevents buttom from obscuring suggested monthly payments.
    padding-left: 0;
    padding-right: 0;
  }

  .price-alert-indicator-modal-title {
    font-family: Human BBY Digital;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    &-sv {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      margin-bottom: 16px;
    }
  }

  .price-alert-indicator-modal-content {
    display: flex;
    gap: 25px;
    font-family: Human BBY Digital;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    & div {
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-sv {
      font-size: 15px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      & div {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .price-alert-button-row {
    display: flex;
    justify-content: center;
    & div {
      flex-grow: 1;
      align-items: center;
    }
    .price-alert-button-separator {
      flex-grow: 0;
    }
  }
  .price-alert-cancel-button,
  .price-alert-go-to-app-button {
    font-family: Human BBY Digital;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    background-color: white;
    border: none;
    color: #0046be;
  }

  .price-alert-separator {
    padding: auto 12px;
  }
}
