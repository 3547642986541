@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';
@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .mainBox {
    margin-top: 15px;
    border: 1px solid #c5cbd5;
    border-radius: 2px;
    padding: 8px 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 8px;
  }

  .logoBox {
    padding-right: 18px;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 70px;
    height: 32px;
  }

  .separator {
    width: 2px;
    height: 24px;
    background-color: #c5cbd5;
  }

  .savingsBox {
    padding-left: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .savingsBoxText {
    font-weight: 500;

  }

  .promoRed {
    color: #e81e25;
    font-weight: 500;
  }

  .newMemberPreview {
    .savingsBoxText {
      font-weight: 400;
    }
  }
}