@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

.one-time-payment a,
.one-time-payment button:not(.c-modal-close-icon),
.citi-payment-option a,
.citi-payment-option button:not(.c-modal-close-icon),
.citizens-payment-option a,
.citizens-payment-option button:not(.c-modal-close-icon) {
  position: relative;
  z-index: 500;
}

.payment-options-title {
  font-weight: 500;
  font-family: 'Human BBY Digital';
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  color: #000000;
  padding-bottom: 8px;
}

.payment-option {
  align-items: flex-start;
  padding: 0;
  text-align: start;
  display: block;
  height: auto;
  padding-bottom: 8px;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  z-index: 2;
}

.payment-option-container {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 25px;
  position: relative;
}