@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";
@import (reference) '~@attach/shared-components/src/components/tabber/tabber.less';

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
    .priceView-total-tech-preview-modal.latestMembership {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;

        .backBox {
            color: #0457C8;
            padding: 8px 17px;
            border-bottom: 1px solid #E6E8E8;
        }

        .backBoxInner {
            display: flex;
            align-items: center;
        }

        .infoBox {
            background-color: #F0F2F4;
            padding: 32px 16px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .memberPricingBox {
            background-color: #FFFFFF;
            padding: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 16px 16px 0px 0px;
            margin-top: -10px;
            padding-bottom: 0;
        }

        .attach-shared-components-tabber();

        .infoBoxTitle {
            font-size: 20px;
            line-height: 24px;
            text-align: left;
            padding-bottom: 42px;
        }

        .infoBoxSmallText {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #040C13;
            text-align: left;
            padding-top: 24px;
        }

        .learnMoreButton {
            margin-top: 16px;
            border-color: #FFFFFF;
            color: #0457C8;
            background-color: #FFF;
            display: flex;
            align-items: center;
            justify-content: start;
            margin-bottom: 24px;
            border-radius: 4px;
            padding: 0;
        }

        .pricingTable {
            border: 1px solid #E6E8E8;
            border-radius: 8px;
        }

        .protectionPlan {
            margin-top: 16px;

            .monthlyProtection {
                line-height: 16px;
                font-size: 13px;
                text-align: left;
            }

            .monthlyValue {
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                color: #55555A;
                text-align: left;
                white-space: nowrap;
            }

            .pricingTableInfo {

                .productImage,
                .protectionIcon,
                .pricingTableInfoPrices {
                    margin: 0 5px;
                }

                .protectionIcon {
                    margin-left: 13.5px;
                    align-self: flex-start;
                }

                .appleCare {
                    width: 24px;
                }

                .productImage {
                    margin-left: 0;
                }
            }
        }

        .pricingTableHeader {
            display: flex;
            padding: 9.5px 0;
            background: #F0F2F4;
            border-radius: 8px 8px 0 0;
            border: 0px solid #E6E8E8;
            border-bottom: 1px solid #C5CBD5;
            justify-content: start;
        }

        .pricingTableHeaderText {
            font-size: 11px;
            font-weight: 500;
            line-height: 13px;
            padding-left: 9px;
            color: #030303;
        }

        .pricingTableHeaderImg {
            height: 13px;
        }

        .memberRelatedPricingDetails {
            text-align: right;
            padding: 16px 12px;

            span {
                display: block;
                font-size: 13px;
                line-height: 16px;
                color: #040C13;
                padding: 2px 0;
            }
        }

        .pricingTableInfo {
            display: flex;
            padding: 16px 12px;
            justify-content: space-between;
            background: #FFFFFF;
            align-items: start;
            border-radius: 0 0 8px 8px;

            .productImage {
                max-width: 40px;
                height: 40px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    height: auto;
                }
            }
        }

        .benefitsList {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 15px;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #55555A;
            align-items: center;

            span {
                margin-right: 12px;
            }
        }

        .memberSavingsRed {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #040C13;

            span {
                color: #E81E25;
                font-weight: 500;
                display: inline;
            }
        }

        .dividerInfo {
            border: 0.5px solid #C5CBD5;
            margin: 0 auto;
        }

        .dividerInfo-block {
            border: 0.5px solid #E0E6EF;
            width: 92%;
            margin: 0 auto;
        }

        .pricingTableInfoPrices {
            display: block;
            text-align: right;

            span {
                display: block;
                text-align: right;
            }

            span.memberBadge {
                font-size: 11px;
                line-height: 14px;
                letter-spacing: -0.05px;
            }

            span.SaveText {
                display: inline-block;
            }

        }

        .autoRenew {
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            display: flex;
            justify-content: space-between;
            padding-top: 4px;
            color: #55555A;
            white-space: nowrap;
        }

        .promoRed {
            color: #E81E25;
        }

        .memberSavingsBox {
            border-bottom: 1px solid #C5CBD5;
            padding: 25px 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #E81E25;
        }

        .memberSavingsBoxTitle {
            font-size: 15px;
        }

        .memberSavingsBoxSavings {
            font-size: 20px;
        }

        .productInfoBox {
            padding: 16px 16px 0 16px;
        }

        .totalTechLogo {
            width: 75px;
            margin-right: 19px;
        }

        .membershipButtomInfo {
            max-width: 205px;
        }

        .atc-button-container {
            padding: 16px;

            .fulfillment-add-to-cart-button .c-button {
                height: 48px;

                >svg {
                    display: none;
                }
            }
        }

        .disclaimerSectionClass {
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            padding: 16px 16px 0 16px;
            color: #55555A;
        }

        .extraDisclaimers {
            padding-top: 20px;
            padding-left: 30px;
            margin-bottom: 24px;
        }

        .add-to-cart-button {
            width: 100%;
        }

        .size-s & {
            z-index: 99;
        }

        .priceView-total-tech-preview-slider-overlay {
            background: rgba(0, 0, 0, .25);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.5s;
        }

        .latestMembership.priceView-total-tech-preview-slider {
            background: @pure-white;
            position: absolute;
            top: 0;
            right: -100%;
            height: 100%;
            width: 100%;
            padding: 0;
            overflow: auto;
            transition: right 0.5s;
            padding-top: 0;

            .container,
            .priceView-total-tech-preview-slider-content {
                height: 100%;
                padding: 0;
                margin: 0;
            }
        }

        .latestMembership.priceView-total-tech-preview-slider {
            padding-top: 9px;
        }

        .largeView {
            max-width: 360px;
            min-width: 360px;
        }

        &.open {
            .priceView-total-tech-preview-slider-overlay {
                opacity: 1;
            }

            .latestMembership.priceView-total-tech-preview-slider {
                right: 0;
            }
        }

        .close-button {
            background: @pure-white;
            display: flex;
            justify-content: center;
            align-content: center;
            position: absolute;
            right: initial;
            left: -36px;
            top: 15px;
            padding: 8px;
            border: 0;
            border-radius: 4px 0 0 4px;
            font-size: 38px;
            font-weight: 300;
            line-height: 0.5;
        }


        .SaveText {
            padding: 2px;
            background: #E81E25;
            font-size: 11px;
            line-height: 14px;
            color: #FFFFFF;
            text-align: center;
            display: inline-block;
        }

        .strikeThrough {
            font-size: 11px;
        }

        .myBestBuyMembership {
            text-align: center;
            padding-top: 20px;

            img {
                width: 45%;
            }
        }

        .tab-carousel-container {
            padding-top: 24px;
            padding-bottom: 0;
            display: block;
            min-width: 360px;
            max-width: 360px;

            .tab-list-ctrl-wrapper {
                button {
                    display: none;
                }

                .tab-list-wrapper {
                    .tab-carousel-list {
                        display: flex;
                        flex-wrap: wrap;
                        max-width: 360px;
                        min-width: 360px;

                        li.tab-carousel-list-item {
                            display: flex;
                            flex: 50%;
                            justify-content: center;
                            margin: 0;
                            padding: 0;

                            a div {
                                font-size: 20px;
                                line-height: 25px;
                                text-align: center;
                                color: #1D252C;
                            }

                            a.selected {
                                border-bottom: 4px solid #0457C8;
                                padding-bottom: 10px;
                                width: 100%;

                                div {
                                    font-weight: 500;
                                }
                            }

                            a:hover,
                            a:focus {
                                text-decoration: none;
                            }
                        }



                    }

                }
            }
        }

    }
}

.modal-open-overflow {
    height: 100vh;
    overflow-y: hidden;
}