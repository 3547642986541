@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .pricing-price {
    .price-box {
      display: flex;
    }

    &__sale-message {
      .font-weight-medium;

      font-size: 15px;
      line-height: 18px;
      color: @tech-black;
      .large({
        font-size: 15px;
        line-height: 19px;
      });
      .medium({
        font-size: 15px;
        line-height: 19px;
      });
      .small({
        font-size: 15px;
        line-height: 19px;
      });
      .mini({
        font-size: 15px;
        line-height: 19px;
        float: left;
        padding-right: 3px;
      });
    }

    &__sale-message-mini {
      .font-weight-medium;
      color: @tech-black;
      font-size: 15px;
      line-height: 19px;
      padding-right: 3px;
    }
  }
}
