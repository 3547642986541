@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .customer-selected-discount {
    margin-top: 8px;

    .priceview-best-buy-text {
      font-weight: 600;
    }

    .apply-offer {
      margin-top: 16px;
      padding: 5px 10px !important;
      border: 1px #c5cbd5;
      border-radius: 4px;
      margin-bottom: 5px;
      cursor: pointer;
      display: flex;
      width: 100%;
      background: none;

      &--solid {
        border-style: solid;
      }

      &--dashed {
        border-style: dashed;
      }

      &__checkbox {
        display: inline-block;
        margin: auto 0;
      }

      &__label {
        font-size: 15px;
        vertical-align: middle;
        margin: auto 0;
      }
    }

    .valid-dates-text {
      display: inline-block;
      &--red-text {
        color: #e81e25;
      }
      font-size: 11px;
    }
    .details-link {
      display: inline-block;
      font-size: 11px;
    }

    .save-text {
      font-size: 13px;
      line-height: 18px;

      &--small-view {
        font-size: 11px;
      }

      &__checkmark {
        height: 16px;
        vertical-align: top;
      }

      &__amount {
        font-weight: 500;
      }
    }
  }
}
