@import (reference) '../../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';
@parent-selector: ~'@{package-namespace}';

.citizens-payment-option {
  width: 100%;
  border: 1px solid @tech-gray;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  text-align: left;
  font-size: 11px;
  &.selected {
    border: 2px solid black;
  }
  &__logo {
    width: 80px;
    float: right;
    & img {
      width: 100%;
    }
  }
  &__before-price {
    font-weight: 500;
  }
  &__price {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &__disclaimer {
    color: @hint-gray;
    margin-bottom: 8px;
  }
  &__learn-more-link a {
    font-weight: 500;
    color: @human-blue;
  }
}
