// @import (reference) '~sc-pattern-library/less/variables.less';
@import (reference) "~@bestbuy/brix-web/less/";

.restricted-pricing {
  &__price-container {
    min-height: 100px;
    overflow: hidden;
  }
  &__heading {
    margin-bottom: 30px;
    color: @tech-black;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    svg {
      width: 20px;
    }
  }

  &__product {
    position: relative;

    &-divider {
      &:before { content: ''; }
      position: absolute;
      left: (100% / 12) * 8;  // 8 columns from the left
      top: -8px;
      background-color: @light-gray;
      width: 1px;
      bottom: 8px;
      display: block;
      margin-left: -15px; // offset the middle column's gutter
    }
  }

  &__product-image {
    max-width: 100%;
    max-height: 170px;
    margin: 0 auto;
    display: block;
  }

  &__product-info {
    padding-right: 16px;
    padding-left: 16px;
  }

  &__product-name {
    .font-weight-normal;

    display: block;
    font-size: 15px;
    line-height: 17px;
    color: @tech-black;
  }

  &__product-model {
    padding: 15px 0 7px;
  }

  &__product-model-info {
    padding-left: 24px;
  }

  &__product-model-label {
    .font-weight-medium;

    font-size: 11px;
    line-height: 13px;
    color: @tech-black;
  }

  &__product-model-text {
    .font-weight-normal;

    font-size: 11px;
    line-height: 13px;
    color: @tech-black;
  }

  &__product-rating {
    display: inline-block;
    margin-left: 8px;
    font-size: 11px;
  }

  &__product-rating-score {
    .font-weight-medium;

    font-size: 13px;
    line-height: 16px;
  }

  &__each-price {
    .font-weight-medium;

    font-size: 30px;
    line-height: 35px;
    color: @tech-black;  // @TO DO Please share the weight again
  }

  &__saving-price {
    .font-weight-medium;

    display: inline-block;
    font-size: 13px;
    line-height: 17px;
    padding: 3px 7px;
    margin-right: 8px;
    color: @pure-white;
    background-color: @value-red;
  }

  &__sale-message {
    margin-top: 10px;
    font-weight: bold;
  }

  &__price-block {
    padding-left: 0px;
  }

  &__regular-price-section {
    .font-weight-normal;

    display: inline-block;
    font-size: 13px;
    line-height: 16px;
  }

  &__regular-price {
    padding-left: 4px;
  }

  &__go-to-cart-button {
    .font-weight-medium;
    width: 100%;
    display: block;
    font-size: 15px;
    line-height: 18px;
    margin-top: 27.5px;
    margin-bottom: 24px;
  }

  &__remove-item {
    .font-weight-normal;

    display: block;
    font-size: 13px;
    line-height: 15px;
    color: @link-color;
  }

  &__disclaimer1 {
    .font-weight-medium;

    display: block;
    font-size: 13px;
    line-height: 15px;
    color: @tech-black;
    padding: 50px 0px 7px 0px;
    margin: 0px;
  }

  &__disclaimer2 {
    .font-weight-normal;

    display: block;
    font-size: 13px;
    line-height: 15px;
    margin: 0px;
    color: @tech-black;
  }
}

.restricted-pricing-modal-content {
  color:@tech-black;
  p {
    margin: 11px 0px;
    color:@tech-black;
    font-size: 13px;
    line-height: 15px;
    strong {
      margin-top: 10px;
      padding-top: 30px;
      display: inline-block;
    }
  }
}
