@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .priceView-offers {
    min-height: 1em;

    button {
      border: none;
      padding: 0;
      background: none;
    }
  }

  .priceView-offers .pl-close {
    display: none;
  }

  .priceView-offer {
    width: 151px;
    height: 16px;
    background-repeat: no-repeat;
    background-size: contain;
    .small({
      height: 12px;
    });
    .mini({
      height: 12px;
    });

    &-badge {
      font-size: 11px;
      height: 13px;
      letter-spacing: -0.26px;
      font-weight: 400;
      color: @tech-black;
      .small ({
        height: 11px;
        font-size: 9px;
      });
      .mini ({
        height: 11px;
        font-size: 9px;
      });
      
      strong {
        font-weight: 700;
      }
    }
  }

  .priceView-my-bby-offer-price {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  a {
    text-decoration: none;
  }

  .priceView-offer-early-access {
    background-image: url('../../images/early-access.png');
  }

  .priceView-offer-gamers-club-unlocked {
    height: 21px;
    background-image: url('../../images/gamers-club-unlocked.png');
  }

  .priceView-offer-gamers-club {
    background-image: url('../../images/gamers-club.png');
  }

  .priceView-offer-loyalty {
    background-image: url('../../images/mybby-loyalty.png');
  }

  .priceView-offer-elite-loyalty {
    background-image: url('../../images/mybby-loyalty-elite.png');
  }

  .priceView-offer-icon-my-bby-member {
    background-image: url('../../images/MyBBY_OfferPricing.png');
  }

  .priceView-offer-elite-plus-loyalty {
    background-image: url('../../images/mybby-loyalty-elite-plus.png');
  }

  .icon-my-bby-offer {
    background-image: url('../../images/MyBBY_MemberOffer.png');
    height: 18px;
    width: 94px;
  }

  .icon-total-tech-support-priceView {
    background-image: url('../../images/total-tech-support-thumb.png');
    height: 13px;
    width: 126px;
    background-size: cover;
  }

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .priceView-offer-early-access {
      background-image: url('../../images/early-access-2x.png');
    }

    .priceView-offer-gamers-club-unlocked {
      height: 21px;
      background-image: url('../../images/gamers-club-unlocked-2x.png');
    }

    .priceView-offer-gamers-club {
      background-image: url('../../images/gamers-club-2x.png');
    }

    .priceView-offer-loyalty {
      background-image: url('../../images/mybby-loyalty-2x.png');
    }

    .priceView-offer-elite-loyalty {
      background-image: url('../../images/mybby-loyalty-elite-2x.png');
    }

    .priceView-offer-elite-plus-loyalty {
      background-image: url('../../images/mybby-loyalty-elite-plus-2x.png');
    }

    .priceView-offer-icon-my-bby-member {
      background-image: url('../../images/MyBBY_OfferPricing_2x.png');
    }

    .icon-my-bby-offer {
      background-image: url('../../images/MyBBY_MemberOffer_2x.png');
      height: 18px !important;
      width: 94px !important;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .icon-total-tech-support-priceView {
      background-image: url('../../images/total-tech-support-thumb-2x.png');
      height: 13px !important;
      width: 126px !important;
      background-size: cover;
    }
  }

  .priceView-offer-overlay {
    font-size: 13px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  .priceView-offer-overlay-headline {
    margin-bottom: 15px;
    font-size: 15px;
  }

  .priceView-offer-overlay-disclaimer {
    margin-top: 15px;
  }

  .priceView-offer-business-Only-Price {
    .font-weight-medium;

    font-size: 15px;
    line-height: 18px;
    color: @tech-black;
    .large({
      font-size: 15px;
      line-height: 18px;
    });
    .medium({
      font-size: 15px;
      line-height: 18px;
    });
    .small({
      font-size: 13px;
      line-height: 16px;
    });
    .mini({
      font-size: 11px;
      line-height: 14px;
    });
  }
}
