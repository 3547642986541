@import (reference) "~@bestbuy/brix-web/less/";

.priceView-offer-badge {
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.26px;
  font-weight: 400;
  color: #1d252c;

  strong {
    font-weight: 700;
  }

  &__bold-text {
    font-weight: 500;
  }

  &__text,
  &__paid-member-icon {
    display: inline-block;
    vertical-align: middle;
  }

  &__paid-member-icon {
    padding-right: 2px;
  }

  .totalTechEarlyAccessTagImg {
    width: 60px;
    margin-right: 2px;
  }
  a:hover{
    text-decoration: none;
  }
}