@import (reference) '~@bestbuy/brix-web/less/';
@import '../common.less';

.priceview-member-savings-message {
  color: @promo-red;
  margin-top: 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.priceview-member-savings-message-icon {
  height: 24px;
  margin-right: 10px;
}
