@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";
@import (reference) "~@bestbuy/brix-web/less/variables";

@parent-selector: ~"@{package-namespace}";
//@font-size-h5 = 20px
.@{parent-selector} {
  .priceView-egp-open-box{
    font-family: 'Human BBY Digital';
		font-weight: @font-weight-medium;
    font-size: @font-size-h5;

    .refurbished-preferred-badge{
      .priceView-offer-badge{
        font-size: 10px;
      }
    }
    .refurbished-sale-message{
      .pricing-price__sale-message{
        font-size: 10px;
      }
    }
  }
}
