@import (reference) '~@bestbuy/brix-web/less/';

@promo-red: #e81e25;

// Use these mixins for small/large/medium/mini/single view
.large(@rules) {
  .priceView-layout-large & {
    @rules();
  }
}

.medium(@rules) {
  .priceView-layout-medium & {
    @rules();
  }
}

.small(@rules) {
  .priceView-layout-small & {
    @rules();
  }
}

.mini(@rules) {
  .priceView-layout-mini & {
    @rules();
  }
  .priceView-mini-price & {
    @rules();
  }
}

.single(@rules) {
  .priceView-layout-single & {
    @rules();
  }
  .priceView-single-price & {
    @rules();
  }
}

.priceView-layout-small.priceView-fixed-height {
  min-height: auto !important; // important for PROMO feature-widget
  .pricing-price {
    position: initial !important; // important for PROMO feature-widget
  }

  .priceView-price {
    height: 90px;
    display: table-cell;
    vertical-align: bottom;
  }
}

.priceView-layout-mini.priceView-fixed-height {
  .priceView-price {
    height: 34px;
    display: table-cell;
    vertical-align: bottom;
    .term-price {
      font-size: 13px;
    }
  }
}

.priceView-align-right {
  .priceView-tap-to-view-price {
    text-align: right;
  }
  text-align: right;
}

:not(priceView-align-right) {
  .priceView-tap-to-view-price {
    text-align: left;
  }
}
