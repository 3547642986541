@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .disclaimer-collapse {
    margin-top: 12px;
    font-size: 11px;
    font-family: 'Human BBY Digital', 'Human BBY Web', Arial, Helvetica,
      sans-serif;
    &__trigger {
      color: @link-color;
      margin-bottom: 4px;
    }
    &__trigger-arrow {
      margin: 0 4px;
      height: 11px;
      width: 11px;
      transition: all 0.1s;
    }
    &__content {
      display: none;
      color: #55555a;
    }
    &--expanded {
      .disclaimer-collapse {
        &__trigger-arrow {
          transform: rotate(180deg);
        }
        &__content {
          display: block;
        }
      }
    }
    &--small-view {
      margin-top: 20px;
    }
  }
}
