@import (reference) '../../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';
@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .upgrade-plus-slidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    cursor: default;

    .backBox {
      color: #0457c8;
      padding: 17px;
      cursor: pointer;
    }
    .backBoxInner {
      display: flex;
      align-items: center;
      & svg {
        position: absolute;
        height: 30px;
        margin-left: -4px;
      }
    }
    .slider-overlay {
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      transition: opacity 0.5s;
    }
    .slider {
      background: @pure-white;
      position: absolute;
      top: 0;
      right: -100%;
      height: 100%;
      // max-width: 360px;
      // min-width: 360px;
      // width: 100%;
      padding: 0;
      transition: right 0.5s;
      padding-top: 9px;
    }
    &.openPlusSidebar {
      .slider-overlay {
          opacity: 1;
      }

      .slider {
          right: 0;
      }
    }
  }
}
