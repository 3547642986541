@import '../../app/common.less';
@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";


@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
    .restricted-pricing__info-form {
        .c-input-error-message {
            margin-bottom: 1rem;
        }
    }
}