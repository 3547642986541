@import './bundle-and-save-disclaimer-collapse.less';
@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .priceView-bundle-and-save {
    .modal-content {
      margin: 0px 36px 0px 59px;
    }
    .offer-icon-modal {
      width: 24px;
      height: 24px;
      vertical-align: bottom;
      background: url('./images/offer-icon-modal.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
    }
    .priceView-anchor {
      position: relative;
    }
    &__modal-add-to-cart-wrapper {
      display: flex;
      justify-content: flex-end;
      padding-top: 8px;
    }
    &__modal-add-to-cart {
      width: 175px;
    }
    &__link {
      border: none;
      padding: 0;
      background-color: transparent;
      line-height: 14px;
      text-align: left;
    }

    &__priceBlock-banner {
      .font-weight-medium;
      white-space: normal !important;

      .offer-icon {
        margin-right: 4px;
      }
      .font-weight-normal {
        font-weight: 400;
      }
      .is-mini {
        font-size: 13px;
      }

      margin: 0;
      color: @human-blue;
      white-space: nowrap;

      .large({
          font-size: 13px;
          line-height: 17px;
          });
      .medium({
          font-size: 13px;
          line-height: 17px;
          });
      .small({
          font-size: 13px;
          line-height: 17px;
          });
      .mini({
          font-size: 11px;
          line-height: 14px;
          .offer-icon{
              width: 14px;
              height: 14px;
          }
          });

      .ficon-special-offers {
        vertical-align: middle !important;
        color: @human-blue !important;
      }

      .ficon-bby-points {
        color: @human-blue !important;
      }
      .offer-icon {
        width: 17px;
        height: 17px;
        vertical-align: bottom;
        background: url('./images/offer-icon.svg') no-repeat center;
        background-size: contain;
        display: inline-block;
      }
    }

    &__banner {
      .font-weight-medium;
      font-size: 20px;
      line-height: 24px;
      .ficon-special-offers {
        font-size: 27px !important;
        line-height: 17px;
        color: @human-blue !important;
      }

      .ficon-bby-points {
        font-size: 17px !important;
        line-height: 20px;
        color: @human-blue !important;
      }
    }

    &__items-in-cart-label {
      font-family: 'Human BBY Digital', 'Human BBY Web', Arial, Helvetica,
        sans-serif;
      color: #1d252c;
      font-style: italic;
      padding-top: 24px;
      margin-left: 5px;
      display: block;
      font-size: 13px;
      line-height: 16px;
    }

    &__savings {
      padding-top: 4px;
      padding-bottom: 15px;
      .font-weight-normal;
      color: #040c13;
      font-size: 15px;
      line-height: 16px;
    }

    &__list {
      padding: 0;
      margin: 10px 0 0;
      list-style: none;
      overflow: hidden;
    }

    &__item:not(:first-of-type) {
      padding-top: 10px;
    }

    &__item + &__item {
      margin-top: 25px;
    }

    &__product {
      overflow: hidden;
      display: flex;
      justify-content: space-between;
    }

    &__image-container {
      flex-basis: 42px;
      display: flex;
      justify-content: center;
    }
    &__image-wrapper {
      height: 42px;
      width: 74px;
      display: block;
      text-align: center;
    }

    &__image {
      max-height: 42px;
      max-width: 74px;
    }

    &__name {
      float: left;
      flex: auto;
      margin-right: 34px;
      max-width: 240px;
      overflow: hidden;
      .font-weight-normal;
      font-size: 13px;
      line-height: 16px;
      color: #1d252c;
    }

    &__name-sv {
      float: left;
      flex: auto;
      margin: 0 24px 0 12px;
      width: 161px;
      overflow: hidden;
      .font-weight-normal;
      font-size: 13px;
      line-height: 16px;
      color: #1d252c;
    }

    &__price {
      float: right;
      text-align: right;
    }

    &__previous-price,
    &__purchase-price {
      margin: 0;
      .font-weight-normal;
      font-size: 15px;
      line-height: 17px;
      color: #1d252c;
      float: left;
      text-transform: uppercase;
    }

    &__previous-price {
      margin: 0;
      font-size: 15px;
      line-height: 17px;
      display: inline-block;
      position: relative;
      color: #040c13;

      &::after {
        content: ' ';
        position: absolute;
        top: 0.6em;
        left: 0;
        width: 100%;
        height: 1px;
        background: #040c13;
      }
    }
  }

  .priceView-bundle-and-save:focus {
    outline: none;
  }

  .priceView-overlay {
    width: 320px;
    padding: 15px 15px 5px;
    position: relative;
    background: @pure-white;
    outline: none;
    .offer-icon {
      width: 20px;
      height: 20px;
      vertical-align: bottom;
      background: url('./images/offer-icon.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
    }
    .spinner {
      display: block;
      margin: 15px auto 0;
    }
  }

  @media only screen and (min-width: @screen-sm-min) {
    .priceView-overlay {
      border: 1px solid @light-gray;
      border-radius: 4px;
      margin-right: 15px;
      position: absolute;
      left: -335px;
      top: -40px;
      -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
    }

    .priceView-overlay-arrow {
      display: block;
      width: 0;
      height: 0;
      border-width: 11px;
      border-style: solid;
      border-color: transparent;
      border-left-color: @light-gray;
      position: absolute;
      top: 46px;
      right: -22px;

      &::after {
        content: ' ';
        display: block;
        width: 0;
        height: 0;
        border-width: 10px;
        border-right-width: 0;
        border-style: solid;
        border-color: transparent;
        border-left-color: @pure-white;
        margin-left: -12px;
        position: absolute;
        top: -10px;
      }
    }

    .priceView-overlay-close-button {
      display: none;
    }
  }

  @media only screen and (max-width: @screen-xs-max) {
    .priceView-overlay-wrapper {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(35, 31, 32, 0.65);
    }

    .priceView-overlay {
      margin: 60px auto 0;
    }

    .priceView-overlay-arrow {
      display: none;
    }

    .priceView-overlay-close-button {
      color: @human-blue;
      position: absolute;
      top: 10px;
      right: 10px;
      -webkit-appearance: none;
      padding: 0;
      cursor: pointer;
      background: 0 0;
      border: 0;
      font-size: 19.5px;
      font-weight: 700;
      line-height: 1;
      text-shadow: 0 1px 0 @pure-white;
    }
  }

  .priceView-bundle-and-save-disclaimer__modal-section {
    .c-overlay-fullscreen {
      z-index: 9002; // its a z-index arms race...  need to fix this later.
    }
  }
}
