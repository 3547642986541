@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .priceview-expiration-timer {
    margin-top: 4px;
    margin-bottom: 8px;
  }
}
