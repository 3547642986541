@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
    .single-price .priceView-hero-price span {
        font-weight: 500;
    }
    .priceView-hero-price {
        .total-membership-type span {
            font-size: 15px;
            line-height: 18px;
            font-weight: 400;
        }

        .total-membership-type.mini span {
            font-size: 11px;
            line-height: 14px;
        }

        .total-membership-type.single span {
            font-size: 13px;
            line-height: 16px;
        }

        .priceView-total-membership-monthly-second-lm {
            font-family: Human BBY Digital;
            font-weight: 400;
            letter-spacing: 0px;
            text-align: left;

            .monthly-price {
                font-weight: 500;
            }
        }
    }
}