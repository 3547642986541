@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';
@parent-selector: ~'@{package-namespace}';

.total-tech {
  &-price {
    margin-top: 12px;
    margin-bottom: 16px;
    &--large {
      margin-top: 8px;
      margin-bottom: 16px;
    }
    &__button {
      font: 13px/15px 'Human BBY Digital', 'Human BBY Web', sans-serif;
      color: @human-blue;
      .font-weight-medium;
    }

    &__text {
      .font-weight-medium;
      color: @tech-black;
    }

    &-savings__text {
      font: 13px/15px 'Human BBY Digital', 'Human BBY Web', sans-serif;
      color: @tech-black;
    }
  }
}

.total-tech-warranty {
  background: @pure-white;
  border: 1px solid @success-green;
  border-radius: 2px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 12px 12px 13px 12px;
  height: 60px;
  width: 100%;

  &__text-full-term-savings {
    font-weight: 500;
  }

  &--large {
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 12px 10px 13px 10px;
  }

  &__icon-pm-savings {
    width: 23.5px;
    height: 21.1px;
    margin-right: 10px;

    &--large {
      width: 21.5px;
    }
  }

  &__icon-with-savings-value-large {
    width: 29.33px;
    height: 29.33px;
  }

  &__icon-pm-savings--green {
    fill: #318000;
  }

  &__text {
    font-family: 'Human BBY Digital', sans-serif;
    font-size: 11px;
    line-height: 14px;
    width: 80%;
    color: @black-text;
    height: 100%;

    &-content {
      margin-bottom: 0;
    }
  }

  &__icon-caret {
    height: 20px;
    color: @black-text;
    margin-left: 5px;
  }
}

.disableTotalTechWarrantySavings {
  display: none;
}