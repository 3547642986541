@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";
@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
    .priceView-total-tech-preview {
        margin-top: 18px;
        font-size: 13px;
        line-height: 18px;
    }

    .greentext {
        font-size: 14px;
    }

    .priceView-ttSavings {
        padding-top: 14px;
    }

    .priceView-ttSavingsButton {
        background-color: white;
        border-color: #C5CBD5;
        padding-left: 4px;
        padding-right: 4px;
    }

    .priceView-ttSaveText {
        white-space: nowrap;
        font-family: Human BBY Digital;
        font-size: 11px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: #E81E25;
    }

    .priceView-ttWithText {
        color: #000000;
        font-family: Human BBY Digital;
        font-size: 11px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
    }

    .priceView-ttText {
        color: #000000;
        font-family: Human BBY Digital;
        font-size: 11px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        padding-right: 5px;
        white-space: nowrap;
    }

    .priceView-carret {
        background-image: url('./images/caret-right.png');
        width: 7px;
        height: 14px;
        background-repeat: no-repeat;
    }

    .priceView-sv-ttSaveText {
        white-space: nowrap;
        font-family: Human BBY Digital;
        font-size: 11px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: left;
        color: #E81E25;
    }

    .priceView-sv-ttWithText {
        color: #000000;
        font-family: Human BBY Digital;
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: left;
    }

    .priceView-sv-ttText {
        color: #000000;
        font-family: Human BBY Digital;
        font-size: 11px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: left;
        padding-right: 5px;
    }

    .priceView-sv-carret {
        background-image: url('./images/caret-right-sv.png');
        width: 6px;
        height: 12px;
        background-repeat: no-repeat;
    }

    .newMemberPreview {
        .priceView-ttText,
        .priceView-sv-ttText {
            font-weight: 400;
            display: inline-block;
        }
    }

    .preview-div-section {
        text-align: left;
        line-height: 14px;
    }
}