@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';
@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
    .trade-in-message-price {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        height: 20px;
        align-items: center;


        .trade-in-message-price-overlay {
            background: #effaf3;
            border-radius: 4px;
            padding: 4px;
            gap: 4px;
            display: flex;
            flex-direction: row;
            height: 20px;
            align-items: center;
        }

        .trade-in-message-price-inner {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #318000;
            align-items: center;
        }
    }

    .due-today {
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
    }

    .upgrade-plus .due-today {
        font-size: 11px;
        line-height: 14px;
    }
}