@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .priceView-cabo {
    &__member-badge {
      margin-top: 10px;
    }
    &__offer-value {
      .font-weight-normal;
      font-size: 16px;
      line-height: 18px;
      color: @tech-black;
      margin-top: 10px;
      margin-right: 2px;
    }

    &__offer-value2 {
      margin: 10px 2px 8px 0;
      display: inline-block;
      letter-spacing: -0.26px;
      strong {
        font-weight: 700;
      }
    }

    &__apply-offer {
      .font-weight-normal;

      font-size: 13px;
      line-height: 16px;
      color: @tech-black;
      display: inline-block;
    }

    &__apply-offer .checkbox {
      display: inline;
    }

    &__apply-offer label {
      padding: 0px;
    }

    &__check-offer-details {
      display: inline-block;
      .font-weight-normal;

      font-size: 11px;
      line-height: 14px;
      color: @tech-black;
    }

    &__expire-disclaimer {
      color: @value-red;
      font-size: 12px;
    }

    .help-block {
      display: none !important;
    }

    .v-m-vertical {
      margin-bottom: 0px;
    }
    margin-top: 16px;
    padding: 0px 10px 10px 10px !important;
    border: 1px solid @light-gray;
    border-radius: 4px;
  }

  .priceView-cabo-offer-detail {
    &__header {
      margin: 0 0 8px 0;
      border-bottom: 1px solid @light-gray;
    }

    &__header-bby-offer {
      .font-weight-normal;

      font-size: 20px;
      font-weight: 500;
      color: @tech-black;
      letter-spacing: 0;
      text-align: left;
      display: inline-block;

      .icon {
        background-image: url('./images/my-bby-stacked-svg-65350.svg') !important;
        background-size: cover;
        height: 24px !important;
        width: 78px !important;
        margin-right: 4px;
      }
    }

    &__header-OFFER {
      vertical-align: top;
      padding-top: 3px;
      font-size: 12px;
    }

    &__disclaimer {
      .font-weight-normal;

      font-size: 11px;
      line-height: 14px;
      color: @dark-gray;
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__disclaimer1 {
      font-weight: 500;
      font-size: 13px;
      padding-bottom: 8px;
    }

    &__disclaimer2 {
      line-height: 13px;
    }

    &__description {
      display: inline-block;
      padding: 20px 0;
      width: 100%;
    }

    &__effectiveDate {
      .font-weight-normal;

      font-size: 13px;
      line-height: 16px;
      color: @tech-black;
      padding-top: 15px;
    }

    &__offer-image-wrapper {
      margin-right: 10px;
      width: 45%;
      float: left;
    }

    &__body {
      width: 50%;
      float: left;
      margin-top: 25px;
      min-width: 170px;
      color: @tech-black;
    }

    &__image {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }

    &__default-image {
      background-image: url('./images/mybby-offer-default.svg') !important;
      height: 185px !important;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &__headline {
      .font-weight-medium;

      font-size: 17px;
      line-height: 18px;
    }

    &__copy {
      padding-top: 15px;
    }
  }
}
