@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .priceView-my-bby-offer-price-block {
    display: block;
    .priceView-offer-badge {
      font-size: 11px;
      line-height: 13px;
    }
  }
}
