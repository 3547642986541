@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .priceView-customer-price {
    .font-weight-medium;
    font-weight: 500;

    .large( {
        font-size: 30px;
        line-height: 35px;
      }

    );

    .medium( {
        font-size: 25px;
        line-height: 29px;
      }

    );

    .small( {
        font-size: 25px;
        line-height: 29px;
      }

    );

    .mini( {
        font-size: 15px;
        line-height: 18px;
      }

    );

    .priceView-subscription-units {
      font-size: 25px;
      line-height: 29px;

      .large( {
          font-size: 25px;
          line-height: 29px;
        }

      );

      .medium( {
          font-size: 20px;
          line-height: 24px;
        }

      );

      .small( {
          font-size: 20px;
          line-height: 24px;
        }

      );

      .mini( {
          font-size: 15px;
          line-height: 18px;
        }

      );
    }

    .priceView-total-tech-units {
      .font-weight-normal;

      font-size: 15px;
      line-height: 18px;

      .large( {
          font-size: 15px;
          line-height: 18px;
        }

      );

      .medium( {
          font-size: 13px;
          line-height: 16px;
        }

      );

      .small( {
          font-size: 13px;
          line-height: 16px;
        }

      );

      .mini( {
          font-size: 11px;
          line-height: 14px;
        }

      );
    }
  }
}