@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .bespoke-min-height-reservation {
    min-height: 82px
  }
  .bespoke-price {
    .font-weight-medium;

    color: @tech-black;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .bespoke-price-wrapper{
    display: flex !important;
    .single-price .priceView-customer-price {
      color: @black-text;
    }
  }
}
