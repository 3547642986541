@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .pricing-price {
    &__regular-price-description-button {
      margin-left: 5px;
      margin-top: -1px;
      width: 14px !important; //conflict with button rule in OPB
      min-width: 0 !important;
    }
    &__regular-price-description-modal {
      text-align: left;
      font-size: 15px;
      & button {
        width: auto !important; //conflict with button rule in OPB
        min-width: 0 !important;
      }
    }
    &__regular-price-description-content {
      white-space: normal;
      font-family: 'Human BBY Digital';
      text-align: left;
      font-size: 15px;
    }
    &__regular-price-description-learn-more-link {
      margin-top: 20px;
    }
  }
}
