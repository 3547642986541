@import (reference) "~@bestbuy/brix-web/less/";
@import (reference) "../../package-namespace.less";

.black-friday-guaranteed-price {
    &__text {
        .font-weight-medium;
        font-size: 11px;
        line-height: 13px;
        color: @tech-black;
    }

    &__button {
        text-align: left;
        .font-weight-medium;

        & span {
            color: @light-blue;
        }
    }

    &-msg {
        font-family: "Human BBY Digital", "Human BBY Web", Arial, Helvetica, sans-serif;

        &__heading {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 16px;
            color: @black-text;
        }

        &__heading--small {
            font-size: 17px;
            line-height: 20px;
        }

        &__body {
            font-size: 13px;
            line-height: 15px;
            color: #040c13;

            &-text {
                color: @tech-black;
            }

            &-lead {
                margin-top: 16px;
                margin-bottom: 16px;
            }

            &-divider {
                width: 100%;
                border: 1px solid #e0e6ef;
                margin-top: 16px;
                margin-bottom: 16px;
            }

            &-divider--short {
                width: 56px;
                border: 1px solid #000000;
                margin-bottom: 16px;
            }

            &-shop-link {
                color: @black-text;

                a {
                    font-weight: 600;
                }
            }

            &-shop-link--small {
                font-size: 15px;
                line-height: 18px;
            }
        }

        &__disclaimer {
            font-size: 11px;
            line-height: 13px;

            &-title {
                font-weight: bold;
                margin-bottom: 0;
                font-size: inherit;
                margin-bottom: 5px;
                color: #55555a;
            }
        }

        &__disclaimer--small {
            margin-top: 34px;
        }
    }
}

.black-friday-deal-message-badge__text {
    .font-weight-medium;
    font-size: 11px;
    line-height: 13px;
    color: @tech-black;

    a:hover {
        text-decoration: none;
    }
}

.lowest-price-of-the-season-badge {
    background-color: @highlight-blue;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;

    &__btn {
        .font-weight-medium;
        font-size: 11px;
        line-height: 13px;
        color: @light-blue;

        &-icon {
            height: 14px;
            width: 15px;
            fill: @human-blue;
            margin-right: 4px;
        }
    }

    &__btn--large {
        font-size: 13px;
        line-height: 15px;
    }

    &__btn--medium {
        font-size: 11px;
        line-height: 13px;
    }

    &__btn--small {
        font-size: 11px;
        line-height: 13px;
    }
}
.black-friday-deal-message-badge__text {
    a:focus {
        text-decoration: none;
    }
    a:hover {
        text-decoration: underline;
    }
}