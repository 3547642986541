@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

.savings-padding-lms {
  padding: 3px 7px;
}

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .pricing-price {
    &__savings-regular-price {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .medium( {
          white-space: normal;
        }

      );

      .small( {
          white-space: normal;
        }

      );
    }

    &__regular-price-content--block {
      display: block;
    }

    &__regular-price-content--block-mt {
      margin-top: 4px;
    }

    &__savings {
      .font-weight-medium;

      display: inline-block;
      letter-spacing: -0.17px;
      margin-right: 4px;
      color: @pure-white;
      background-color: @value-red;

      .large( {
          font-size: 15px;
          line-height: 18px;
          .savings-padding-lms();
        }

      );

      .medium( {
          font-size: 13px;
          line-height: 16px;
          .savings-padding-lms();
        }

      );

      .small( {
          font-size: 13px;
          line-height: 16px;
          .savings-padding-lms();
        }

      );

      .mini( {
          font-size: 11px;
          line-height: 14px;
          padding: 1.5px 4px;
          margin-right: 5px;
        }

      );

      .priceView-saving-subscription-units {
        text-transform: lowercase;
        font-size: inherit;
      }

      &--promo-red {
        background-color: #e81e25;
      }
    }

    &__savings--green {
      background-color: @success-green;
    }

    &__savings--bg-green {
      background-color: @success-green;
    }

    &__savings--bg-green-ab {
      background-color: @success-green;
      color: @pure-white;
    }

    &__savings--text-red-ab {
      color: @value-red;
      background-color: @pure-white;
      letter-spacing: -0.17px;
      padding: 3px 7px 3px 0px;

      .large( {
          padding: 3px 7px 3px 0px;
        }

      );
    }

    &__savings--block {
      display: block;
      margin-right: 7px;
      padding: 0 !important;
    }

    &__savings-alone {
      margin-right: 0;
      letter-spacing: -0.17px;
    }

    &__regular-price {
      .font-weight-normal;

      display: inline-block;
      font-size: 13px;
      line-height: 16px;

      .large( {
          font-size: 12px;
          line-height: 18px;
          color: @dark-gray;
        }

      );

      .medium( {
          font-size: 12px;
          line-height: 17px;
        }

      );

      .small( {
          font-size: 12px;
          line-height: 17px;
        }

      );
    }

    .estimated-trade-in-applied {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
      padding-top: 6px;
      color: #1d252c;
    }
  }
}

.priceView-layout-medium {
  .@{parent-selector} {
    .pricing-price__savings--promo-red {
      background-color: transparent !important;
      color: #e81e25 !important;
      padding: 0 !important;
      padding-right: 7px !important;
    }
    .pricing-price__regular-price {
      color: #6c6f75 !important;
    }
  }
}
