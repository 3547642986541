@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .priceView-price-disclaimer {
    .font-weight-normal;

    margin-bottom: 2px;
    font-size: 15px;
    line-height: 18px;
    color: @tech-black; // @TO DO Please share the weight again
    .large({
        font-size: 15px;
        line-height: 18px;
      });
    .medium({
        font-size: 15px;
        line-height: 18px;
      });
    .small({
        font-size: 15px;
        line-height: 18px;
      });
    .mini({
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.6px
      });

    &__activation {
      display: inline-block;
      padding-right: 5px;
      font-size: inherit;
    }
  }
}
