@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .open-box-option {
    display: block;

    a:hover span {
      text-decoration: none;
    }

    &__button {
      text-align: left !important;
    }

    &__label {
      .font-weight-medium;

      display: inline-block;
      font-size: 13px;
      line-height: 17px;
      color: @tech-black;
    }

    &__label-medium {
      .font-weight-normal;

      font-weight: 500;
      font-size: 13px;
      color: @tech-black;
      letter-spacing: -0.2px;
    }

    &__link {
      .font-weight-normal;

      white-space: nowrap;
      font-size: 13px;
      line-height: 17px;
      color: @link-color;
    }

    &__link-medium {
      .font-weight-normal;

      font-weight: 400;
      font-size: 13px;
      color: @link-color;
      letter-spacing: -0.4px;
    }

    &__label-medium-sv {
      .font-weight-normal;

      font-weight: 500;
      font-size: 11px;
      color: @tech-black;
      letter-spacing: -0.2px;
    }

    &__link-medium-sv {
      .font-weight-normal;

      font-weight: 400;
      font-size: 11px;
      color: @link-color;
      letter-spacing: -0.4px;
    }
  }

  .priceView-open-box-title {
    .font-weight-medium;

    font-size: 15px;
    color: @tech-black;
  }

  .priceView-open-box-see-all-buying-options {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid @light-gray;
  }

  .priceView-open-box-price {
    .priceView-open-box-on-sale {
      font-size: 15px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .open-box-lowest-price {
      .font-weight-medium;

      font-size: 25px;
      line-height: 29px;
    }
  }
}
