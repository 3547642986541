@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";
@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
    .priceView-trade-in-messaging {
        margin-top: 12px;
        font-size: 13px;
        line-height: 18px;
        
        span {
            color: @success-green;
        }
    }
    .priceView-deferred-trade-in {
      margin: 25px auto;
    }
}

.priceView-trade-in-messaging-modal-content {
    .title {
        font-size: 25px;
    }

    .bottomPadding {
        padding-bottom: 41px;
    }

    .sub-header {
        font-size: 17px;
    }

    .body {
        font-size: 13px;
    }

    ol {
        padding-left: 14px;
    }

    ul {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-right: 22px;
    }

    ol, ul {
        line-height: 17px;
    }

    ol > li {
        margin-bottom: 12px;
    }

    ul > li {
        margin-bottom: 6px;
    }

    .unordered-list-container {
        background-color: @tech-white;
    }

    .close-btn-container {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .close-btn {
            width: 222px;
        }
    }
}