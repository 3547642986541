@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .priceView-egp-open-box{
    font-family: 'Human BBY Digital';
		font-size: 20px;
		font-weight: 500;
  }
}
