@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .restricted-price-previous-price {
    font-family: "Human BBY Digital", "Human BBY Web", Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-size: 25px;
    line-height: 29px;
    text-decoration: line-through;
    color: #1d252c;
  }

  .priceView-restricted-price-checkout {
    .font-weight-normal;

    border: none;
    padding: 0px;
    margin: 0px;
    background-color: transparent;
    font-size: 13px;
    line-height: 16px;
    color: @human-blue;  // @TO DO Please share the weight again
    .large({
      font-size: 13px;
      line-height: 16px;
    });
    .medium({
      font-size: 13px;
      line-height: 16px;
    });
    .small({
      font-size: 13px;
      line-height: 16px;
    });
    .mini({
      font-size: 13px;
      line-height: 16px;
      white-space: nowrap;
    });
  }

  .priceView-minimum-advertised-price {
    .font-weight-normal;

    padding: 0px;
    margin: 0px;
    border: none;
    background-color: transparent;
    font-size: 13px;
    line-height: 16px;
    color: @human-blue;  // @TO DO Please share the weight again
    .large({
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
    });
    .medium({
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
    });
    .small({
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
    });
    .mini({
      font-size: 13px;
      line-height: 16px;
    });
  }

  .priceView-restricted-price-checkout-link {
    .font-weight-normal;

    padding: 0px;
    margin: 0px;
    border: none;
    background-color: transparent;
    font-size: 13px;
    line-height: 16px;
    color: @human-blue;  // @TO DO Please share the weight again
    .large({
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
    });
    .medium({
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
    });
    .small({
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
    });
    .mini({
      font-size: 13px;
      line-height: 16px;
    });
  }

  .priceView-tap-to-view-price {
    border: none;
    color: @link-color;
    padding: 0px;
    background-color: transparent;

    .price-box {
      display: flex;
    }

    &-bold {
      font-weight: 500;
    }

    &-icr {
      font-family: "Human BBY Digital", "Human BBY Web", Arial, Helvetica, sans-serif;
      color: #040C13;
    }
  }
}
