@import (reference) '../../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

// .@{parent-selector} {
.upgradePlus-citizens-payment-modal-container {
  cursor: default;
}
.upgradePlus-citizens-payment-modal {
  width: 560px;
  margin: 50px;
  font-family: 'Human BBY Digital';
  font-size: 15px;
  &__progress-bar-and-labels {
    margin-bottom: 30px;
  }
  &__progress-bar {
    margin-bottom: 20px;
  }
  &__progress-label {
    display: flex;
    margin-bottom: 16px;
  }
  &__progress-label-title {
    font-weight: 500;
    margin-bottom: 6px;
  }
  &__progress-label-text {
    margin-bottom: 6px;
  }
  &__progress-chip {
    flex: 35px 0 0;
  }
  &__item-details {
    font-size: 13px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: flex-start;
  }
  &__item-image {
    width: 50px;
    flex-shrink: 0;
    & img {
      width: 100%;
    }
  }
  &__item-title {
    flex-grow: 1;
    margin: 0 12px 0 8px;
  }
  &__boxed {
    border: 1px solid @light-gray;
    padding: 16px;
  }
  &__item-price {
    font-size: 11px;
    flex: 0 0 50%;
    & span {
      padding-left: 12px;
    }
    & .emphasis {
      font-size: 13px;
      font-weight: 500;
    }
    & > div {
      margin-bottom: 8px;
    }
    & > div:last-child {
      margin-bottom: 0;
    }
    & hr {
      margin: 8px 0;
    }
    & .two-col {
      display: flex;
      width: '100%';
      & div:first-child {
        max-width: 50%;
      }
    }
    & .column-2 {
      flex-grow: 1;
      text-align: right;
    }
  }
  &__item-disclaimer {
    font-size: 9px;
    color: @hint-gray;
  }
  &__section {
    margin-bottom: 28px;
    & div {
      font-size: 15px;
      margin-bottom: 8px;
    }
  }
  & &__option {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid @light-gray;
    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    & div:last-child {
      margin-bottom: 0;
    }
  }
  &__faq-link {
    font-weight: bold;
    margin-bottom: 12px;
  }
  &__flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
  }
  &__section-title {
    font-size: 24px;
    font-weight: 500;
    margin: 24px auto 12px;
  }
  &__disclaimer {
    font-size: 11px;
    color: @hint-gray;
    margin-bottom: 8px;
    & .emphasis {
      font-weight: 500;
    }
    & .emphasis2 {
      font-weight: 500;
      text-decoration: underline;
    }
  }
  &__inline-link {
    font-weight: 500;
  }
}
.upgradePlus-citizens-payment-modal__small {
  margin: 0;
  width: 100%;
  padding: 20px;
  font-size: 13px;
}
.upgradePlus-citizens-payment-modal__small .upgradePlus-citizens-payment-modal {
  &__section-title {
    font-size: 15px;
    margin: 20px auto 12px;
  }
//   &__boxed {
//     padding: 8px;
//   }
  &__item-details {
    display: block;
    font-size: 11px;
  }
  &__item-image-and-name {
    padding-bottom: 8px;
    border-bottom: 1px solid @light-gray;
    margin-bottom: 8px;
  }
  &__item-image {
    width: 50px;
    flex-shrink: 0;
    & img {
      width: 100%;
    }
  }
  &__item-title {
    flex-grow: 1;
    max-width: fit-content;
    margin: 0;
    margin-left: 20px;
  }
}
