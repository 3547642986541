@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

.payment-options-title {
    font-weight: 500;
    font-family: 'Human BBY Digital';
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    color: #000000;
    padding-bottom: 8px;
}

.payment-option {
    align-items: flex-start;
    padding: 0;
    text-align: start;
    display: block;
    height: auto;
    padding-bottom: 8px;
    width: 100%;
}

.payment-qualification {
    font-family: 'Human BBY Digital';
    font-size: 11px;
    line-height: 13px;
    color: #000000;
    padding-top: 14px;
    font-weight: 500;
    width: 100%;
    overflow:hidden; 
    white-space:nowrap;
    & hr {
      display: inline-block; 
      width:100%;
      margin: 0 0 2px 5px;
      border-top: 1px solid @tech-gray;
    }
}