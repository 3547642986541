// @import (reference) '~sc-pattern-library/bootstrap/less/mixins/clearfix.less';
@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@btnPadding: 10px;
@topOffset: 4px;
@thinBorderWidth: 1px;
@thickBorderWidth: 2px;
@borderDelta: @thickBorderWidth - @thinBorderWidth;

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .priceView-down-payment {
    margin-top: 15px;
    font-size: 11px;
  }

  .activated-pricing {
    font-size: 15px;
    margin-bottom: 15px;

    &__header {
      font-size: 13px;
      margin-bottom: 8px;
    }

    &__options {
      .clearfix();

      padding: 0;
      margin: 0;
    }

    &__option {
      display: block;
      float: left;
      width: 100%;
      margin-bottom: 4% !important;

      &-price {
        .font-weight-medium;

        font-size: 15px;
        line-height: 18px;
        display: block;
      }
    }

    &__description {
      .font-weight-normal;

      font-size: 13px;
      line-height: 17px;
    }

    &__button {
      width: 100%;
      border: solid @thinBorderWidth @light-gray;
      border-radius: 3px;
      background: none;
      text-align: left;
      position: relative;
      padding: @btnPadding;
      color: @tech-black;

      /* TODO - better selector ? */
      /* mixin of some sort? */
      & > div:first-child {
        position: absolute;
        top: @btnPadding - @topOffset;
        padding-right: @btnPadding + @thickBorderWidth;
      }

      &:hover {
        border: solid @thinBorderWidth @human-blue;
        padding: @btnPadding;
        color: @tech-black;

        & > div:first-child {
          top: @btnPadding - @topOffset - @borderDelta;
          padding-right: @btnPadding + @thinBorderWidth;
        }
      }

      &--selected {

        &, &:hover {
          border: solid @thickBorderWidth @tech-black;
          padding: @btnPadding - @borderDelta;
          color: @tech-black;

          & > div:first-child {
            top: @btnPadding - @topOffset - @borderDelta;
            padding-right: @btnPadding + @thinBorderWidth;
          }
        }
      }

      &--disabled {
        &, &:hover {
          border: solid @thinBorderWidth @light-gray;
          padding: @btnPadding;
          background: @tech-white;
          color: @dark-gray;

          & > div:first-child {
            top: @btnPadding - @topOffset;
          }
        }
      }
    }

    &__arrow {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0;
      width: 0;
      border: solid 5px transparent;
      border-bottom: solid 7px @tech-black;
    }

    &__disclaimer {
      min-height: 32px;
      font-size: 11px;
    }

    &__see-details-section {
      font-size: 11px;
    }

    &__modal-label {
      margin: 5px 0px 7px 0px;
      .font-weight-medium;

      font-size: 13px;
      line-height: 16px;

      .c-info-i {
        display: inherit;
        margin-left: 5px;
        font-size: 15px;

        &-circle {
          position: inherit;
          margin-top: 3px;
        }

        &-icon {
          position: relative;
          margin-left: -11px;
          top: -3.2px;
        }
      }

      .ficon-info {
        vertical-align: top;
      }
    }
  }
}
