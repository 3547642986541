@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .pv-add-on-price {
    font-family: @font-family-base;
    &__member-badge {
      font-size: 9px;
      line-height: 11px;
    }
    &__customer-price {
      font-size: 13px;
      font-weight: 500;
    }
    &__regular-price {
      font-size: 13px;
      text-decoration: line-through;
    }
  }

  .pv-add-on-price--primary {
    .pv-add-on-price {
      &__customer-price {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
      }
      &__regular-price {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }

  .pv-member-badge__text,
  .pv-member-badge__icon {
    display: inline-block;
    vertical-align: middle;
  }

  .pv-member-badge__icon {
    padding-right: 2px;
  }

  .pv-member-badge__text--bold {
    font-weight: 500;
  }

  .pv-add-on-price.pv-align-right {
    text-align: right;
  }
  .pv-restricted-price {
    border: none;
    color: @link-color;
    padding: 0px;
    background-color: transparent;
    font-size: 13px;

    &-bold {
      font-weight: 500;
    }
  }
}
