@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .priceView-annotations {
    .annotations-content {
      .font-weight-normal;

      &__title {
        .font-weight-normal;
        text-align: left;
        line-height: 14px;
        font-size: 13px;
        word-wrap: break-word;
        display: inline;
        padding-right: 2px;
        font-weight: 600 !important;
        display: block;
        margin: 10px 0 0 0;
      }

      &__subhead {
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        display: block;
        margin: 0 0 10px 0;
      }
    }
  }
}
