@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .single-price {
    .font-weight-medium;

    font-size: 13px;
    line-height: 16px;
    color: @tech-black;
    font-weight: 400;

    &.single-bold {
      font-weight: 500;
    }

    &.single-red {
      .priceView-hero-price {
        color: @value-red;
      }
    }

    .term-price {
      font-size: 13px;
    }

    .priceView-hero-price {
      font-size: 13px;
      line-height: 16px;
    }
  }
}
