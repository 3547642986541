@import (reference) "../../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

.upgrade-plus {
    width: 100%;
    border: 1px solid #90959E;
    border-radius: 4px;
    padding: 16px;
    &.selected {
      border: 2px solid black;
    }  

    .one-time-payment {
        padding-top: 6px;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        color: #1D252C;
    }

    .priceView-tap-to-view-price {
        button.priceView-tap-to-view-price {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #040C13;
        }

        button.priceView-tap-to-view-price.priceView-tap-to-view-price-bold {
            color: #0457C8;
            font-size: 13px;
            line-height: 16px;
            min-width: 52px;
        }
    }

    button.priceView-tap-to-view-price.priceView-tap-to-view-price-bold {
        font-size: 11px;
        line-height: 14px;
    }


    .pricing-price.pricing-lib-price-4-6-7.priceView-price {
        display: flex;
        flex: 1;
        flex-direction: row;
    }

    .priceView-tap-to-view-price {
        flex: 1;
    }

    .upgrade-plus-price {
        display: flex;

        .priceView-hero-price.priceView-customer-price {
            line-height: 18px;
            color: #1D252C;
            font-weight: 500;
            font-size: 15px;
        }

        .pricing-price__savings-regular-price {
            .pricing-price__savings {
                color: #E81E25;
                font-size: 11px;
                line-height: 14px;
                font-weight: 500;
                background-color: transparent;
            }

            .pricing-price__regular-price {
                font-size: 11px;
                line-height: 14px;
                color: #000000;
            }

        }

        .pricing-price__regular-price-content {
            margin-top: 0;
            display: block;
        }

        .price-sale-message {
            display: inline-flex;
            flex: 1;
            flex-direction: column;

            .pricing-price__sale-message {
                font-weight: 500;
                font-size: 11px;
                line-height: 14px;
            }
        }

        .one-time-payment {

            &-savings-customer-price {
                text-align: right;
            }

        }

        .priceView-offer-badge__text {
            font-size: 9px;
            line-height: 11px;
        }

        .save-bundle-price {
            text-align: right;
        }

        .save-bundle-price .priceView-bundle-and-save__link .priceView-bundle-and-save__priceBlock-banner {
            font-size: 11px;
            line-height: 14px;

            span {
                display: block;
                text-align: right;
            }
        }

        .pricing-price.pricing-lib-price-4-6-7.priceView-price {
            width: 100%;
            flex: 3;
            flex-direction: row;
        }

    }
}