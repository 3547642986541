@import (reference) "~@bestbuy/brix-web/less/";

.suggested-finance-options {
    display: flex;
    position: relative;

    .c-modal-grid,
    .c-modal-small-view {
        background-color: #e0e6ef;
    }

    .content-divider {
      border-left: 1px solid #000;
      position: relative;
      padding: 13px 0;
      display: inline-block;
      margin: 7px 10px 0 10px;
      height: 26px;

      span {
        background: #fff;
        padding: 0px 0;
        font-size: 9px;
        position: absolute;
        top: 7px;
        left: -7px;
      }
    }
    .monthly-payment-details {
        padding-bottom: 5px;
    }
    .payment-details {
        font-size: 25px;
        margin-top: 5px;
        font-weight: 500;
    }
    .static-details {
        font-size: 15px;
        font-weight: 500;
    }
    .static-message {
        font-size: 11px;
    }
    .finance-message {
        font-size: 11px;
        font-weight: 500;
        padding-bottom: 5px;
    }
    .finance-modal-link {
        font-size: 11px;
        font-weight: 500;
        color: @link-color;
    }

}
.pricing-finance-options {
    .finance-modal__container{
        padding: 25px;
    }
}