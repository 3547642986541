@import (reference) "../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .pricing-price {
    &__savings-regular-price {
      white-space: nowrap;
      .medium({
        white-space: normal;
      });
      .small({
        white-space: normal;
      });  
    }


    &__regular-price-content--block {
      display: block;
    }

    &__regular-price-content--block-mt {
      margin-top: 4px;
    }

    &__savings {
      .font-weight-medium;

      display: inline-block;
      letter-spacing: -0.17px;
      margin-right: 4px;
      color: @pure-white;
      background-color: @value-red;

      .large({
        font-family: 'Human BBY Digital', sans-serif;
        font-size: 15px;
        line-height: 18px;
        padding: 3px 7px;
      });
      .medium({
        font-size: 13px;
        line-height: 16px;
        padding: 3px 7px;
      });
      .small({
        font-size: 13px;
        line-height: 16px;
        padding: 3px 7px;
      });
      .mini({
        font-size: 11px;
        line-height: 14px;
        padding: 1.5px 4px;
        margin-right: 5px;
      });

      .priceView-saving-subscription-units {
        text-transform: lowercase;
        font-size: inherit;
      }
      &--promo-red{
        background-color: #e81e25;
      }
    }

    &__savings--green {
      background-color: @success-green;
    }

    &__savings--bg-green {
      background-color: @success-green;
    }

    &__savings--bg-green-ab {
      background-color: @success-green;
      color: @pure-white;
    }

    &__savings--text-red-ab {
      color: @value-red;
      background-color: @pure-white;
      letter-spacing: -0.17px;
      padding: 3px 7px 3px 0px;
      .large({
        padding: 3px 7px 3px 0px;
      });
    }

    &__savings--block {
      display: block;
      margin-right: 0;
      padding: 0 !important;
    }

    &__savings-alone {
      margin-right: 0;
      letter-spacing: -0.17px;
    }

    &__regular-price {
      .font-weight-normal;

      display: inline-block;
      font-size: 13px;
      line-height: 16px;
      .large({
        font-size: 15px;
        line-height: 18px;
        color: @dark-gray;
      });
      .medium({
        font-size: 13px;
        line-height: 17px;
      });
      .small({
        font-size: 13px;
        line-height: 17px;
      });
    }
  }
}
