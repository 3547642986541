@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
  .XboxAllAccessPrice {
    > .monthlypayment {
      font-weight: 500;
      &.large {
        font-size: 30px;
        line-height: 35px;
      }
      &.medium {
        font-size: 25px;
        line-height: 29px;
      }
      &.small {
        font-size: 25px;
        line-height: 29px;
      }
      &.mini {
        font-size: 15px;
        line-height: 18px;
      }
      &.single {
        font-size: 13px;
        line-height: 16px;
      }
    }
    > .finance-terms {
      font-size: 15px;
      font-weight: 18px;
      &.large {
        font-size: 15px;
        line-height: 18px;
      }
      &.medium {
        font-size: 15px;
        line-height: 18px;
      }
      &.small {
        font-size: 15px;
        line-height: 18px;
      }
      &.mini {
        font-size: 11px;
        line-height: 13px;
      }
    }
    > .payment-terms {
      font-size: 11px;
      line-height: 13px;
      float: left;
    }
    > .offer-details {
      &.large {
        display: inline;
        font-size: 11px;
        line-height: 13px;
        padding: 0;
        height: auto;
        vertical-align: baseline;
        margin-left: 5px;
      }
      &.medium {
        font-size: 13px;
        line-height: 16px;
        margin-top: 16px;
      }
      &.small {
        font-size: 13px;
        line-height: 16px;
        margin-top: 16px;
      }
      &.mini {
        font-size: 15px;
        line-height: 18px;
        margin-top: 22px;
      }
    }
  }
}
