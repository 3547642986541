@import '../../app/common.less';
@import (reference) '../../package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

@parent-selector: ~'@{package-namespace}';

.@{parent-selector} {
    .payment-from-text {
        font-family: 'Human BBY Digital';
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        /* identical to box height */
    
    
        /* Grayscale/Tech Black */
    
        color: #1D252C;
    }
    .installment-text {
        font-family: 'Human BBY Digital';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
    
        /* Grayscale/Tech Black */
    
        color: #1D252C;
    
    }

    .pv-upgrade-plus-attach-price {
        text-align: left;
        white-space: nowrap;
    }

    .installment-range-text {
        font-family: 'Human BBY Digital';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        /* identical to box height */
    
    
        /* Grayscale/Tech Black */
    
        color: #1D252C;
    }
    .original-price-text {
        font-family: 'Human BBY Digital';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        padding-top: 8px;
        /* Grayscale/Pure Black */
    
        color: #000000;
    }
    .payment-info-text {
        font-family: 'Human BBY Digital';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        /* identical to box height */
    
    
        /* Grayscale/Tech Black */
    
        color: #1D252C;
    }

}
