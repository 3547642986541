@import (reference) './package-namespace.less';
@import (reference) '~@bestbuy/brix-web/less/';

.price-view-pb {
  version: @package-namespace;
}

// @import '~sc-pattern-library/less/theme.colors.less';
// @import '~sc-pattern-library/less/theme.component.less';
// @import (reference) '~sc-pattern-library/less/theme.fonts.less';
// @import '~sc-pattern-library/less/theme.forms.less';
// @import '~sc-pattern-library/less/theme.grid.less';
// @import '~sc-pattern-library/less/theme.media-queries.less';
// @import '~sc-pattern-library/less/theme.typography.less';
// @import '~sc-pattern-library/less/theme.z-index.less';

@import './common/common.less';

@import './price/single-price/single-price.less';
@import './price/package-price/package-price.less';
@import './price/bespoke-price/bespoke-price.less';
@import './apps/restricted-pricing/less/index.less';
@import './price/hero-price/price.less';
@import './price/customer-account-basedOffer/offer-details.less';
@import './price/single-use-couponOffer/offer-details.less';
@import './price/customer-selected-discount/customer-selected-discount.less';
@import './price/hero-price/previous-price.less';
@import './price/hero-price/customer-price.less';
@import './price/bundle-and-save/bundle-and-save.less';
@import './apps/buying-options-price/lowest-open-box-price/open-box.less';

@import './apps/buying-options-price/buy-new-price/buy-new-option.less';

@import './apps/xbox-all-access/xbox-all-access.less';
@import './apps/xbox-all-access/terms-and-condition/terms-and-condition.less';

@import './price/total-cost-clarity/total-cost-clarity.less';
@import './price/trade-in-messaging/trade-in-messaging.less';
@import './price/buy-now-pay-later/buy-now-pay-later.less';
@import './price/annotations/annotations.less';

@import './activated/less/activated-pricing-options.less';
@import './activated/less/pricing-options-modal.less';
@import './price/suggested-finance-options/suggested-finance-options.less';
@import './price/ShimmerPriceSkeleton/ShimmerPriceSkeleton.less';
@import './price/price-badge/price-badge.less';
@import './price/price-change-message/price-change-message.less';

@import './price/holiday-price-deal-message/holiday-price-deal-message.less';
@import './price/holiday-price-deal-message/holiday-price-puck-modal.less';
@import './price/total-tech/total-tech.less';

@import './price/upgrade-plus-price/one-time-payment/one-time-payment.less';
@import './price/upgrade-plus-price/upgrade-plus-payment-options.less';
@import './price/upgrade-plus-price/citi-payment-option/citi-payment-option.less';
@import './price/upgrade-plus-price/citizens-payment-option/citizens-payment-option.less';
@import './price/upgrade-plus-price/citizens-payment-modal/citizens-payment-modal.less';
@import './price/upgrade-plus-price/small-price/upgrade-plus-small-price.less';

@import './price/total-tech-preview/total-tech-preview.less';

@import './price/total-tech-membership/total-tech-membership.less';
@import './price/total-tech-membership/total-tech-membership-modal.less';
@import './price/upgrade-plus-price/upgrade-plus-slidebar/upgrade-plus-slidebar.less';
@import './price/trade-in-messaging/after-trade-in-estimate.less';
@import './price/trade-in-messaging/trade-in-info-overlay.less';