@import (reference) "../../../package-namespace.less";
@import (reference) "~@bestbuy/brix-web/less/";

@parent-selector: ~"@{package-namespace}";

.@{parent-selector} {
  .priceView-buy-new-option {
    &__label {
      .font-weight-medium;

      display: inline-block;
      color: @tech-black;
      font-size: 13px;
      line-height: 17px;
    }

    &__label-medium {
      .font-weight-normal;

      font-weight: 500;
      font-size: 13px;
      color: @tech-black;
    }

    &__label-medium-sv {
      .font-weight-normal;

      font-weight: 500;
      font-size: 11px;
      color: @tech-black;
      letter-spacing: -0.2px;
    }

    &__price {
      .font-weight-normal;

      white-space: nowrap;
      font-size: 13px;
      line-height: 17px;
      color: @human-blue;
    }

    &__price-medium {
      .font-weight-normal;

      font-weight: 400;
      font-size: 13px;
      color: @human-blue;
    }

    &__price-medium-sv {
      .font-weight-normal;

      font-weight: 400;
      font-size: 11px;
      color: @human-blue;
    }

    &__unavailable {
      .font-weight-normal;

      white-space: nowrap;
      font-size: 13px;
      line-height: 17px;
      color: @dark-gray;
    }

    &__unavailable-medium {
      .font-weight-normal;

      font-weight: 400;
      font-size: 13px;
      color: @dark-gray;
    }

    &__unavailable-medium-sv {
      .font-weight-normal;

      font-weight: 400;
      font-size: 11px;
      color: @dark-gray;
    }
  }
}
